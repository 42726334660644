import {
  REQUEST_RETURN_SAVINGS_DURATION_ARRAY_START,
  REQUEST_RETURN_SAVINGS_DURATION_ARRAY_SUCCESS,
  REQUEST_RETURN_SAVINGS_DURATION_ARRAY_ERROR,
} from 'constants/action-types/savings';
import apiAction from 'helpers/apiAction';

export default data => dispatch =>
  dispatch(
    apiAction({
      method: 'post',
      url: '/ReturnSavingsDurationArray',
      data,
      onStart: () => dispatch =>
        dispatch({
          type: REQUEST_RETURN_SAVINGS_DURATION_ARRAY_START,
        }),
      onSuccess: data => dispatch => {
        return dispatch({
          type: REQUEST_RETURN_SAVINGS_DURATION_ARRAY_SUCCESS,
          payload: { operatorData: data },
        });
      },
      onFailure: error => dispatch => {
        return dispatch({
          type: REQUEST_RETURN_SAVINGS_DURATION_ARRAY_ERROR,
          payload: {
            operatorError: error[0],
          },
        });
      },
    }),
  );
