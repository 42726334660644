// CREATE BULK PAYMENT
export const CREATE_BULK_PAYMENT_START = 'CREATE_BULK_PAYMENT_START';
export const CREATE_BULK_PAYMENT_SUCCESS =
  'CREATE_BULK_PAYMENT_SUCCESS';
export const CREATE_BULK_PAYMENT_ERROR = 'CREATE_BULK_PAYMENT_ERROR';
export const CREATE_BULK_PAYMENT_END = 'CREATE_BULK_PAYMENT_END';
export const CLEAR_CREATE_BULK_PAYMENT_STORE =
  'CLEAR_CREATE_BULK_PAYMENT_STORE';

// GET BULK PAYMENT LIST
export const GET_BULK_PAYMENT_LIST_START =
  'GET_BULK_PAYMENT_LIST_START';
export const GET_BULK_PAYMENT_LIST_SUCCESS =
  'GET_BULK_PAYMENT_LIST_SUCCESS';
export const GET_BULK_PAYMENT_LIST_ERROR =
  'GET_BULK_PAYMENT_LIST_ERROR';
export const GET_BULK_PAYMENT_LIST_END = 'GET_BULK_PAYMENT_LIST_END';

// GET BULK PAYMENT DETAILS
export const GET_BULK_PAYMENT_DETAILS_START =
  'GET_BULK_PAYMENT_DETAILS_START';
export const GET_BULK_PAYMENT_DETAILS_SUCCESS =
  'GET_BULK_PAYMENT_DETAILS_SUCCESS';
export const GET_BULK_PAYMENT_DETAILS_ERROR =
  'GET_BULK_PAYMENT_DETAILS_ERROR';
export const GET_BULK_PAYMENT_DETAILS_END =
  'GET_BULK_PAYMENT_DETAILS_END';
export const CLEAR_BULK_PAYMENT_DETAILS =
  'CLEAR_BULK_PAYMENT_DETAILS';

// ADD BULK PAYMENT RECIPIENT
export const ADD_BULK_PAYMENT_RECIPIENT_START =
  'ADD_BULK_PAYMENT_RECIPIENT_START';
export const ADD_BULK_PAYMENT_RECIPIENT_SUCCESS =
  'ADD_BULK_PAYMENT_RECIPIENT_SUCCESS';
export const ADD_BULK_PAYMENT_RECIPIENT_ERROR =
  'ADD_BULK_PAYMENT_RECIPIENT_ERROR';
export const ADD_BULK_PAYMENT_RECIPIENT_END =
  'ADD_BULK_PAYMENT_RECIPIENT_END';

// DUPLICATE  BULK PAYMENT OPS
export const DUPLICATE_BULK_PAYMENT_POS_START =
  'DUPLICATE_BULK_PAYMENT_POS_START';
export const DUPLICATE_BULK_PAYMENT_POS_SUCCESS =
  'DUPLICATE_BULK_PAYMENT_POS_SUCCESS';
export const DUPLICATE_BULK_PAYMENT_POS_ERROR =
  'DUPLICATE_BULK_PAYMENT_POS_ERROR';
export const DUPLICATE_BULK_PAYMENT_POS_END =
  'DUPLICATE_BULK_PAYMENT_POS_END';

// MODIFY BULK PAYMENT RECIPIENT
export const MODIFY_BULK_PAYMENT_RECIPIENT_START =
  'MODIFY_BULK_PAYMENT_RECIPIENT_START';
export const MODIFY_BULK_PAYMENT_RECIPIENT_SUCCESS =
  'MODIFY_BULK_PAYMENT_RECIPIENT_SUCCESS';
export const MODIFY_BULK_PAYMENT_RECIPIENT_ERROR =
  'MODIFY_BULK_PAYMENT_RECIPIENT_ERROR';
export const MODIFY_BULK_PAYMENT_RECIPIENT_END =
  'MODIFY_BULK_PAYMENT_RECIPIENT_END';

// CALCULATE BULK PAYMENT OPERATION
export const CALCULATE_BULK_PAYMENT_OPERATION_START =
  'CALCULATE_BULK_PAYMENT_OPERATION_START';
export const CALCULATE_BULK_PAYMENT_OPERATION_SUCCESS =
  'CALCULATE_BULK_PAYMENT_OPERATION_SUCCESS';
export const CALCULATE_BULK_PAYMENT_OPERATION_ERROR =
  'CALCULATE_BULK_PAYMENT_OPERATION_ERROR';
export const CALCULATE_BULK_PAYMENT_OPERATION_END =
  'CALCULATE_BULK_PAYMENT_OPERATION_END';

// EXECUTE BULK PAYMENT OPERATION
export const EXECUTE_BULK_PAYMENT_OPERATION_START =
  'EXECUTE_BULK_PAYMENT_OPERATION_START';
export const EXECUTE_BULK_PAYMENT_OPERATION_SUCCESS =
  'EXECUTE_BULK_PAYMENT_OPERATION_SUCCESS';
export const EXECUTE_BULK_PAYMENT_OPERATION_ERROR =
  'EXECUTE_BULK_PAYMENT_OPERATION_ERROR';
export const EXECUTE_BULK_PAYMENT_OPERATION_END =
  'EXECUTE_BULK_PAYMENT_OPERATION_END';

// GET BULK PAYMENT TEMPLATE
export const GET_BULK_PAYMENT_TEMPLATE_START =
  'GET_BULK_PAYMENT_TEMPLATE_START';
export const GET_BULK_PAYMENT_TEMPLATE_SUCCESS =
  'GET_BULK_PAYMENT_TEMPLATE_SUCCESS';
export const GET_BULK_PAYMENT_TEMPLATE_ERROR =
  'GET_BULK_PAYMENT_TEMPLATE_ERROR';
export const GET_BULK_PAYMENT_TEMPLATE_END =
  'EXECUTE_BULK_PAYMENT_TEMPLATE_END';

// GET HELPER LIST

export const GET_HELPER_LIST_START = 'GET_HELPER_LIST_START';
export const GET_HELPER_LIST_SUCCESS = 'GET_HELPER_LIST_SUCCESS';
export const GET_HELPER_LIST_ERROR = 'GET_HELPER_LIST_ERROR';
export const GET_HELPER_LIST_END = 'GET_HELPER_LIST_END';

// ADD HELPER

export const ADD_HELPER_START = 'ADD_HELPER_START';
export const ADD_HELPER_SUCCESS = 'ADD_HELPER_SUCCESS';
export const ADD_HELPER_ERROR = 'ADD_HELPER_ERROR';
export const ADD_HELPER_END = 'ADD_HELPER_END';
export const CLEAR_ADD_HELPER_STORE = 'CLEAR_ADD_HELPER_STORE';

export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SUCCESS =
  'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';
export const CREATE_ORGANIZATION_END = 'CREATE_ORGANIZATION_END';
export const CLEAR_CREATE_ORGANIZATION_STORE =
  'CLEAR_CREATE_ORGANIZATION_STORE';

export const GET_ORGANIZATION_LIST_START =
  'GET_ORGANIZATION_LIST_START';
export const GET_ORGANIZATION_LIST_SUCCESS =
  'GET_ORGANIZATION_LIST_SUCCESS';
export const GET_ORGANIZATION_LIST_ERROR =
  'GET_ORGANIZATION_LIST_ERROR';
export const GET_ORGANIZATION_LIST_END = 'GET_ORGANIZATION_LIST_END';
export const CLEAR_GET_ORGANIZATION_LIST_STORE =
  'CLEAR_CREATE_ORGANIZATION_STORE';

// Get Savings Dashboard KPI
export const GET_SAVINGS_KPI_START = 'GET_SAVINGS_KPI_START';
export const GET_SAVINGS_KPI_SUCCESS = 'GET_SAVINGS_KPI_SUCCESS';
export const GET_SAVINGS_KPI_ERROR = 'GET_SAVINGS_KPI_ERROR';

export const DELETE_BULK_PAYMENT_OPERATION_START =
  'DELETE_BULK_PAYMENT_OPERATION_START';
export const DELETE_BULK_PAYMENT_OPERATION_SUCCESS =
  'DELETE_BULK_PAYMENT_OPERATION_SUCCESS';
export const DELETE_BULK_PAYMENT_OPERATION_ERROR =
  'DELETE_BULK_PAYMENT_OPERATION_ERROR';
export const DELETE_BULK_PAYMENT_OPERATION_END =
  'DELETE_BULK_PAYMENT_OPERATION_END';
export const CLEAR_DELETE_BULK_PAYMENT_OPERATION_STORE =
  'CLEAR_ADD_HELPER_STORE';
