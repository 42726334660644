import * as ActionType from 'constants/action-types/gimac';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.GIMAC_PURCHASE_CREDIT_START:
      return {
        ...state,
        purchaseCredit: {
          ...state.purchaseCredit,
          loading: true,
          error: null,
        },
      };
    case ActionType.GIMAC_PURCHASE_CREDIT_SUCCESS:
      return {
        ...state,
        purchaseCredit: {
          ...state.purchaseCredit,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GIMAC_PURCHASE_CREDIT_FAILURE:
      return {
        ...state,
        purchaseCredit: {
          ...state.purchaseCredit,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GIMAC_PURCHASE_CREDIT_CLEAR:
      return {
        ...state,
        purchaseCredit: {
          ...state.purchaseCredit,
          data: null,
          error: null,
          loading: false,
        },
        transferConfirmation: {
          ...state.transferConfirmation,
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
