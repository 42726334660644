import {
  CLEAR_FAILED_OPERATION,
  OPERATION_FAILED,
} from 'constants/events/operations';

export default response => dispatch =>
  dispatch({
    type: OPERATION_FAILED,
    payload: response,
  });

export const clearFailedOperationData = () => dispatch => {
  dispatch({
    type: CLEAR_FAILED_OPERATION,
  });
};
