export const CANCEL_TRANSACTION_START = 'CANCEL_TRANSACTION_START';
export const CANCEL_TRANSACTION_ERROR = 'CANCEL_TRANSACTION_ERROR';
export const CANCEL_TRANSACTION_SUCCESS =
  'CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_VOUCHER_START = 'CANCEL_VOUCHER_START';
export const CANCEL_VOUCHER_ERROR = 'CANCEL_VOUCHER_ERROR';
export const CANCEL_VOUCHER_SUCCESS = 'CANCEL_VOUCHER_SUCCESS';
export const CLEAR_CANCEL_TRANSACTION = 'CLEAR_CANCEL_TRANSACTION';
export const CANCEL_OTHER_TRANSACTION_START =
  'CANCEL_OTHER_TRANSACTION_START';
export const CANCEL_OTHER_TRANSACTION_ERROR =
  'CANCEL_OTHER_TRANSACTION_ERROR';
export const CANCEL_OTHER_TRANSACTION_SUCCESS =
  'CANCEL_OTHER_TRANSACTION_SUCCESS';
export const CLEAR_CANCEL_OTHER_TRANSACTION =
  'CLEAR_CANCEL_OTHER_TRANSACTION';
