// GetBillsCategoriesList
import {
  VERIFY_GATEWAY_ACCOUNT_START,
  VERIFY_GATEWAY_ACCOUNT_SUCCESS,
  VERIFY_GATEWAY_ACCOUNT_ERROR,
  CLEAR_VERIFY_GATEWAY_ACCOUNT,
} from 'constants/action-types/payBills/getBillsCategories';
import apiAction from 'helpers/apiAction';

export default data => dispatch =>
  dispatch(
    apiAction({
      method: 'post',
      url: '/VerifyGatewayAccount',
      data: data,
      onStart: () => dispatch =>
        dispatch({
          type: VERIFY_GATEWAY_ACCOUNT_START,
        }),
      onSuccess: data => dispatch => {
        return dispatch({
          type: VERIFY_GATEWAY_ACCOUNT_SUCCESS,
          payload: {
            data: data,
          },
        });
      },
      onFailure: error => dispatch => {
        return dispatch({
          type: VERIFY_GATEWAY_ACCOUNT_ERROR,
          payload: {
            error: error[0],
          },
        });
      },
    }),
  );

export const clearVerifyGateway = () => dispatch => {
  return dispatch({
    type: CLEAR_VERIFY_GATEWAY_ACCOUNT,
  });
};
