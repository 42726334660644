export const BULK_PAYMENT_CALCULATION_IN_PROGRESS =
  'BulkPaymentCalculationInProgress';
export const BULK_PAYMENT_CALCULATION_DONE =
  'BulkPaymentCalculationDone';
export const BULK_PAYMENT_CALCULATION_IN_START =
  'BULK_PAYMENT_CALCULATION_IN_START';

export const BULK_PAYMENT_EXECUTION_IN_PROGRESS =
  'BulkPaymentExecutionInProgress';
export const BULK_PAYMENT_EXECUTION_DONE = 'BulkPaymentExecutionDone';
export const BULK_PAYMENT_EXECUTION_IN_START =
  'BULK_PAYMENT_EXECUTION_IN_START';
