import * as ActionType from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.ADD_HELPER_START:
      return {
        ...state,
        addHelpers: {
          ...state.addHelpers,
          loading: true,
          error: null,
        },
      };
    case ActionType.ADD_HELPER_SUCCESS:
      return {
        ...state,
        addHelpers: {
          ...state.addHelpers,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.ADD_HELPER_ERROR:
      return {
        ...state,
        addHelpers: {
          ...state.addHelpers,
          data: null,
          error: payload,
          loading: false,
        },
      };

    case ActionType.CLEAR_ADD_HELPER_STORE: {
      return {
        ...state,
        addHelpers: {
          ...state.addHelpers,
          error: null,
          loading: false,
          data: null,
        },
      };
    }

    default:
      return null;
  }
};
