export default {
  confirmTransaction: {
    confirmationError: null,
    checking: false,
    confirmationData: null,
  },
  moveFundsTo2UWallet: {
    error: null,
    loading: false,
    data: null,
  },
  transferToOthers: {
    error: null,
    loading: false,
    data: null,
  },
  sendFundsToGateway: {
    error: null,
    loading: false,
    data: null,
  },
  gatewayTransactionConfirmation: {
    error: null,
    loading: false,
    data: null,
  },
  transferFundsInternal: {
    error: null,
    loading: false,
    data: null,
  },
  cashback: {
    error: null,
    loading: false,
    data: null,
  },
  addMoneyFromPayPal1: {
    error: null,
    loading: false,
    data: null,
  },
};
