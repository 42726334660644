import React, { useState, useEffect } from 'react';
import { Button, Image, Input, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Parser from 'react-xml-parser';
import CustomModal from 'components/common/CustomModal';
import WalletDropDown from 'components/common/WalletDropDown';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import InfosIcon from 'assets/images/infos-icon.svg';
import './index.scss';

const CreateSavingsPlanModal = ({
  open,
  onClose,
  currentWallet,
  setCurrentWallet,
  walletList,
  durationList,
  onHandleSelectDuration,
  selectedDuration,
  form,
  onCreateSaving,
  onHandleChangeInput,
  savingsTransferConfirmation,
}) => {
  return (
    <CustomModal open={open} size={'tiny'}>
      <div className="container__column">
        <div className="form-box__item">
          <div className="form-box">
            <h3>{global.translate('Apply for savings')}</h3>
            {walletList?.length !== 0 ? (
              <>
                <div className="form-input">
                  <span>{global.translate('Select wallet')}</span>

                  <WalletDropDown
                    walletList={walletList}
                    currentOption={currentWallet}
                    setCurrentOption={setCurrentWallet}
                  />
                </div>
              </>
            ) : (
              <div className="load-data">
                <Image
                  src={LoadWalletImg}
                  className="animate-placeholder"
                />
              </div>
            )}

            <div className="form-input" style={{ marginTop: '10px' }}>
              <span>{global.translate('Amount')}</span>
              <Input
                name="amount"
                placeholder={global.translate('Amount')}
                size="large"
                value={form.amount}
                onChange={onHandleChangeInput}
                style={{ width: '100%' }}
                labelPosition="right"
                label={currentWallet?.CurrencyCode}
              />
            </div>

            <div className="form-input" style={{ marginTop: '10px' }}>
              <span>{global.translate('Choose a savings plan')}</span>
              <Select
                placeholder={global.translate(
                  'Choose a savings plan',
                )}
                options={
                  durationList &&
                  durationList.map((duration, index) => ({
                    text: `${duration.value} ${duration.text}`,
                    value: duration.value,
                    key: index,
                  }))
                }
                onChange={onHandleSelectDuration}
                value={selectedDuration}
                style={{ width: '100%' }}
              />
            </div>

            <div className="alert-box medium-margin-top">
              <Image src={InfosIcon} />
              <span className="alert-message medium-margin">
                {global.translate(
                  'Your funds are securely set aside, and when the agreed-upon time arrives, the money is automatically disbursed directly into your wallet. There is no action required from your side.',
                )}
              </span>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Button className="close-btn__style" onClick={onClose}>
                {global.translate('Cancel')}
              </Button>

              <Button
                onClick={onCreateSaving}
                className="save-btn"
                style={{ width: '180px' }}
                disabled={form.amount === '' ? true : false}
                loading={savingsTransferConfirmation?.loading}
              >
                {global.translate('Proceed')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

CreateSavingsPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default CreateSavingsPlanModal;
