import { BULK_PAYMENT_EXECUTION_DONE } from 'constants/events/bulkPayment';

export default (state, { type, payload }) => {
  switch (type) {
    case BULK_PAYMENT_EXECUTION_DONE: {
      return {
        ...state,
        bulkPaymentExecution: {
          ...state.bulkPaymentExecution,
          data: payload,
        },
      };
    }

    default:
      return null;
  }
};
