import React, { useEffect, useState } from 'react';
import CustomModal from 'components/common/CustomModal';
import { Button, Divider, Image, Input } from 'semantic-ui-react';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import WalletDropDown from 'components/common/WalletDropDown';
import InfosIcon from 'assets/images/danger-info-icon.svg';
import './index.scss';
import { IoIosArrowDropdown } from 'react-icons/io';

const ClaimPrematurePayout = ({
  open,
  onClose,
  currentWallet,
  setCurrentWallet,
  walletList,
  selectedItem,
  onSavingsCancelConfirmation,
  savingsCancelConfirmation,
}) => {
  const [currentSelectedWallet, setCurrentSelectedWallet] = useState(
    {},
  );
  useEffect(() => {
    if (selectedItem) {
      setCurrentSelectedWallet(
        () =>
          walletList.filter(
            wallet =>
              wallet?.AccountNumber === selectedItem?.WalletNumber,
          )[0],
      );
    }
  }, [selectedItem]);

  return (
    <CustomModal open={open} size={'tiny'}>
      <div className="container__column">
        <div className="form-box__item">
          <div className="form-box">
            <h3>{global.translate('Claim premature payout')}</h3>
            <div className="form-input" style={{ marginTop: '10px' }}>
              <span>{global.translate('Amount')}</span>
              <Input
                disabled={true}
                placeholder={global.translate('Amount')}
                size="large"
                value={selectedItem?.Capital}
                style={{ width: '100%' }}
                labelPosition="right"
                label={{
                  basic: true,
                  content: currentWallet?.CurrencyCode,
                }}
              />
            </div>

            <div className="alert-box1 large-margin-top">
              <Image src={InfosIcon} />
              <span className="alert-message1 medium-margin">
                {global.translate(
                  'You will be charged the cancellation fees if you claim prematurely this savings. Would you like to continue?',
                )}
              </span>
            </div>

            <Divider horizontal>
              <IoIosArrowDropdown size={30} color="#d8d8d8" />
            </Divider>

            {walletList?.length !== 0 ? (
              <>
                <div
                  className="form-input"
                  style={{ marginTop: '10px' }}
                >
                  <span>{global.translate('Recipient wallet')}</span>
                  <WalletDropDown
                    disabled
                    walletList={walletList}
                    currentOption={currentSelectedWallet}
                    setCurrentOption={setCurrentWallet}
                  />
                </div>
              </>
            ) : (
              <div className="load-data">
                <Image
                  src={LoadWalletImg}
                  className="animate-placeholder"
                />
              </div>
            )}

            <div style={{ marginTop: '20px' }}>
              <Button className="close-btn__style" onClick={onClose}>
                {global.translate('Cancel')}
              </Button>
              <Button
                className="save-btn"
                loading={savingsCancelConfirmation?.loading}
                onClick={onSavingsCancelConfirmation}
              >
                {global.translate('Proceed')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ClaimPrematurePayout;
