export default {
  cardOperationFees: {
    error: null,
    loading: false,
    success: false,
    message: '',
    Fees: '',
    TotalAmount: '',
    Currency: '',
    justAdded: false,
  },
  payPalOperationFees: {
    error: null,
    loading: false,
    success: false,
    message: '',
    Fees: '',
    TotalAmount: '',
    Currency: '',
    justAdded: false,
    AddMoneyCurrency: '',
    AddMoneyFlatFee: '',
    AddMoneyExternalRate: '',
    Description: '',
    Warning: '',
  },
  addMoneyFromCreditCard: {
    error: null,
    loading: false,
    success: false,
    justAdded: false,
    data: null,
  },
  addMoneyFromPayPal: {
    error: null,
    loading: false,
    success: false,
    justAdded: false,
    data: null,
  },
  addMoneyFromMobileOperator: {
    error: null,
    loading: false,
    data: null,
  },
  gatewayAddMoneyFees: {
    error: null,
    loading: false,
    data: null,
  },
};
