import * as ActionType from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.GET_BULK_PAYMENT_LIST_START:
      return {
        ...state,
        bulkPaymentList: {
          ...state.bulkPaymentList,
          loading: true,
          error: null,
        },
      };
    case ActionType.GET_BULK_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        bulkPaymentList: {
          ...state.bulkPaymentList,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GET_BULK_PAYMENT_LIST_ERROR:
      return {
        ...state,
        bulkPaymentList: {
          ...state.bulkPaymentList,
          data: payload,
          error: null,
          loading: false,
        },
      };

    default:
      return null;
  }
};
