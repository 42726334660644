import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

const ReloadApp = ({ show, onReload, onCancel }) => {
  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      size="mini"
      open={show}
    >
      <Modal.Header>{global.translate('Update')}</Modal.Header>
      <Modal.Content>
        <p>
          {global.translate(
            'A new version is available, reload and close all tabs',
          )}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={onCancel}
          content={global.translate('Cancel')}
        />
        <Button
          onClick={onReload}
          content={global.translate('Reload')}
          className="text-white-important darken-blue-important"
          icon="refresh"
          labelPosition="right"
        />
      </Modal.Actions>
    </Modal>
  );
};

ReloadApp.propTypes = {
  show: PropTypes.bool,
  onReload: PropTypes.func,
  onCancel: PropTypes.func,
};

ReloadApp.defaultProps = {
  show: true,
  onReload: () => undefined,
  onCancel: () => undefined,
};

export default ReloadApp;
