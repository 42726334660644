// GET SAVED CONTACTS
export const GET_CONTACT_EXTERNAL_START =
  'GET_CONTACT_EXTERNAL_START';
export const GET_CONTACT_EXTERNAL_SUCCESS =
  'GET_CONTACT_EXTERNAL_SUCCESS';
export const GET_CONTACT_EXTERNAL_ERROR =
  'GET_CONTACT_EXTERNAL_ERROR';
export const GET_CONTACT_EXTERNAL_END = 'GET_CONTACT_EXTERNAL_END';

// TRANSFER CONFIRMATION
export const TRANSFER_CONFIRMATION_START =
  'TRANSFER_CONFIRMATION_START';
export const TRANSFER_CONFIRMATION_SUCCESS =
  'TRANSFER_CONFIRMATION_SUCCESS';
export const TRANSFER_CONFIRMATION_ERROR =
  'TRANSFER_CONFIRMATION_ERROR';
export const TRANSFER_CONFIRMATION_CLEAR =
  'TRANSFER_CONFIRMATION_CLEAR';

// TRANSFER MONEY
export const GIMAC_TRANSFER_MONEY_START =
  'GIMAC_TRANSFER_MONEY_START';
export const GIMAC_TRANSFER_MONEY_SUCCESS =
  'GIMAC_TRANSFER_MONEY_SUCCESS';
export const GIMAC_TRANSFER_MONEY_FAILURE =
  'GIMAC_TRANSFER_MONEY_FAILURE';
export const GIMAC_TRANSFER_MONEY_CLEAR =
  'GIMAC_TRANSFER_MONEY_CLEAR';

// Merchant Pay
export const GIMAC_MERCHANT_PAY_START = 'GIMAC_MERCHANT_PAY_START';
export const GIMAC_MERCHANT_PAY_SUCCESS =
  'GIMAC_MERCHANT_PAY_SUCCESS';
export const GIMAC_MERCHANT_PAY_FAILURE =
  'GIMAC_MERCHANT_PAY_FAILURE';
export const GIMAC_MERCHANT_PAY_CLEAR = 'GIMAC_MERCHANT_PAY_CLEAR';

// Pay Bills
export const GIMAC_PAY_BILLS_START = 'GIMAC_PAY_BILLS_START';
export const GIMAC_PAY_BILLS_SUCCESS = 'GIMAC_PAY_BILLS_SUCCESS';
export const GIMAC_PAY_BILLS_FAILURE = 'GIMAC_PAY_BILLS_FAILURE';
export const GIMAC_PAY_BILLS_CLEAR = 'GIMAC_PAY_BILLS_CLEAR';

// CardLess ATM
export const GIMAC_CARDLESS_ATM_START = 'GIMAC_CARDLESS_ATM_START';
export const GIMAC_CARDLESS_ATM_SUCCESS =
  'GIMAC_CARDLESS_ATM_SUCCESS';
export const GIMAC_CARDLESS_ATM_FAILURE =
  'GIMAC_CARDLESS_ATM_FAILURE';
export const GIMAC_CARDLESS_ATM_CLEAR = 'GIMAC_CARDLESS_ATM_CLEAR';

// Merchant Pay
export const GIMAC_PURCHASE_CREDIT_START =
  'GIMAC_PURCHASE_CREDIT_START';
export const GIMAC_PURCHASE_CREDIT_SUCCESS =
  'GIMAC_PURCHASE_CREDIT_SUCCESS';
export const GIMAC_PURCHASE_CREDIT_FAILURE =
  'GIMAC_PURCHASE_CREDIT_FAILURE';
export const GIMAC_PURCHASE_CREDIT_CLEAR =
  'GIMAC_PURCHASE_CREDIT_CLEAR';
