/* eslint-disable import/no-named-as-default */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DashboardLayout from 'components/common/DashboardLayout';
import GoBack from 'components/common/GoBack';
import WelcomeBar from 'components/Dashboard/WelcomeSection';
import isAppDisplayedInWebView from 'helpers/isAppDisplayedInWebView';
import getBillsCategoriesListData from 'redux/actions/payBills/getBillsCategoriesListData';
import { Image, Loader } from 'semantic-ui-react';
import { NCardComponent } from 'components/common/BottomMenu/Card';
import loadTransactions from 'assets/images/transactions/load-transactions.svg';

const NewPayBills = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickHandler = () => history.goBack();

  const [billsCategoriesList, setBillsCategoriesList] = useState();

  const {
    payBills: {
      getBillsCategories: { billsCategories },
    },
    user: {
      userData: { data },
    },
  } = useSelector(state => state);

  const operationCountry = data?.OperationCountry;

  const billsCategoriesElements = useCallback(
    billsCategories => {
      setBillsCategoriesList(() =>
        billsCategories?.flatMap(billCategory => {
          return {
            Category: billCategory.Category,
            Icon: readSVGIcon({ icon: billCategory.Icon }),
            IconType: billCategory.IconType,
            Name: billCategory.Name,
          };
        }),
      );
    },
    [setBillsCategoriesList],
  );

  useEffect(() => {
    if (billsCategories) {
      billsCategoriesElements(billsCategories);
    } else {
      getBillsCategoriesListData({
        CountryCode: operationCountry,
      })(dispatch);
    }
  }, [billsCategories]);

  const onClickGoToNextPage = ({ data, billsCategory }) => {
    const name = history.push({
      pathname: pathName({ item: billsCategory.Name }),
      state: {
        country: operationCountry,
        category: billsCategory.Category,
        operationName: billsCategory.Name,
      },
    });
  };

  return (
    <>
      <DashboardLayout>
        <WelcomeBar>
          <div className="head-content">
            {!isAppDisplayedInWebView() && (
              <div className="go-back">
                <GoBack style onClickHandler={onClickHandler} />
              </div>
            )}
            <h2 className="head-title">
              {global.translate('Pay bills')}
            </h2>
            <div className="clear" />
          </div>
        </WelcomeBar>

        <div className="wrap__container">
          {!billsCategoriesList ? (
            <div className="services-container">
              <Image
                src={loadTransactions}
                style={{ width: '100%' }}
              />
            </div>
          ) : (
            <div className="services-container">
              <h3>{global.translate('Pay bills')}</h3>
              <div className="container-subtitle">
                {global.translate(
                  'From your digital wallet to any destination of your choice',
                )}
              </div>
              <div className="services-cards">
                {billsCategoriesList &&
                  billsCategoriesList?.flatMap(
                    (billsCategory, index) => {
                      return (
                        <NCardComponent
                          key={index}
                          type="pay-bills"
                          image={billsCategory?.Icon}
                          onClick={() =>
                            onClickGoToNextPage({
                              data: data,
                              billsCategory: billsCategory,
                            })
                          }
                          title={global.translate(
                            billsCategory?.Name,
                          )}
                        />
                      );
                    },
                  )}
              </div>
            </div>
          )}
        </div>
      </DashboardLayout>
    </>
  );
});

const pathName = ({ item }) => {
  const formattedName = `/${item}`.replace(/ /g, '-').toLowerCase();
  return `/pay-bills${formattedName}`.replace(/&/g, 'and');
};

const readSVGIcon = ({ icon }) => {
  const buff = new Buffer(icon);
  const base64data = buff.toString('base64');
  return `data:image/svg+xml;base64,${base64data}`;
};

NewPayBills.propTypes = {
  payBills: PropTypes.instanceOf(Object),
};

NewPayBills.defaultProps = {
  payBills: {},
};
export default NewPayBills;
