import { toast } from 'react-toastify';
import {
  GATEWAY_TRANSFER_CONFIRMATION_ERROR,
  GATEWAY_TRANSFER_CONFIRMATION_START,
  GATEWAY_TRANSFER_CONFIRMATION_SUCCESS,
  CLEAR_GATEWAY_TRANSFER_CONFIRMATION_SUCCESS,
} from 'constants/action-types/moneyTransfer';
import apiAction from 'helpers/apiAction';

export default data => dispatch => {
  return dispatch(
    apiAction({
      method: 'post',
      url: '/GatewayTransferConfirmation',
      data,
      onStart: () => dispatch =>
        dispatch({
          type: GATEWAY_TRANSFER_CONFIRMATION_START,
          payload: data,
        }),
      onSuccess: data => dispatch => {
        return dispatch({
          type: GATEWAY_TRANSFER_CONFIRMATION_SUCCESS,
          payload: data,
        });
      },
      onFailure: error => dispatch => {
        toast.error(global.translate(error[0]?.Description));
        return dispatch({
          type: GATEWAY_TRANSFER_CONFIRMATION_ERROR,
          payload: error,
        });
      },
    }),
  );
};

export const clearGatewayTransferConfirmation = () => dispatch => {
  dispatch({
    type: CLEAR_GATEWAY_TRANSFER_CONFIRMATION_SUCCESS,
  });
};
