import {
  REQUEST_CASHBACK_START,
  REQUEST_CASHBACK_SUCCESS,
  REQUEST_CASHBACK_ERROR,
  REQUEST_CASHBACK_CLEAR,
} from 'constants/action-types/moneyTransfer';

export default (state, { type, payload }) => {
  switch (type) {
    case REQUEST_CASHBACK_START:
      return {
        ...state,
        cashback: {
          ...state.cashback,
          loading: true,
          error: null,
        },
      };
    case REQUEST_CASHBACK_ERROR:
      return {
        ...state,
        cashback: {
          ...state.cashback,
          error: payload,
          loading: false,
        },
      };
    case REQUEST_CASHBACK_SUCCESS:
      return {
        ...state,
        cashback: {
          ...state.cashback,
          error: null,
          loading: false,
          data: payload,
        },
      };
    case REQUEST_CASHBACK_CLEAR: {
      return {
        ...state,
        cashback: {
          ...state.cashback,
          error: null,
          loading: false,
          data: null,
        },
      };
    }
    default:
      return null;
  }
};
