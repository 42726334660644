export const SELF = '0';
export const CELINE_MONEY = '1';
export const COUPON_CARD = '2';
export const DEBIT_CARD = '3';
export const BANK_TRANSFER = '4';
export const WESTERN_UNION = '5';
export const MONEY_GRAM = '6';
export const PAYPAL = '7';
export const TELECOM_OPERATOR = '8';
export const CASH_OUT = '9';
export const SUPPLIERS_PAY_BILLS = '10';
export const VIRTUAL_CARD = '11';
export const CASH_POINT_INCOME = '12';
export const SAVINGS = '13';
export const CARD_LESS_ATM = '14';
export const BUS_TICKER = '15';
export const PAYING_LOCAL_TAXES = '16';
export const ELECTRICITY_AND_WATER = '17';
export const INSURANCE_FEES = '18';
export const WARI = '19';
export const IN_TOUCH = '20';
export const TELCO = '21';
export const PARTNERS = '22';
export const TV = '23';
export const MERCHANT = '24';
export const STORE_VOUCHER = '25';
export const TAXES = '26';
export const EDUCATION = '27';
export const HEALTH = '28';
export const HOTEL = '29';
export const RESTAURANT = '30';
export const SHOPS_AND_SUPERMARKET = '31';
