import initialState from 'redux/initial-states/dashboard';
import returnSavingsDurationArray from './returnSavingsDurationArray';
import getSavingsList from './getSavingsList';
import savingsTransferConfirmation from './savingsTransferConfirmation';
import executeSavingsPlan from './executeSavingsPlan';
import savingsCancelConfirmation from './savingsCancelConfirmation';
import savingsCancelPlan from './savingsCancelPlan';

export default (state = initialState, action = {}) => ({
  ...state,
  ...returnSavingsDurationArray(state, action),
  ...getSavingsList(state, action),
  ...savingsTransferConfirmation(state, action),
  ...executeSavingsPlan(state, action),
  ...savingsCancelConfirmation(state, action),
  ...savingsCancelPlan(state, action),
});
