import {
  REQUEST_RETURN_SAVINGS_DURATION_ARRAY_START,
  REQUEST_RETURN_SAVINGS_DURATION_ARRAY_SUCCESS,
  REQUEST_RETURN_SAVINGS_DURATION_ARRAY_ERROR,
  CLEAR_REQUEST_RETURN_SAVINGS_DURATION_ARRAY,
} from 'constants/action-types/savings';

export default (state, { type, payload }) => {
  switch (type) {
    case REQUEST_RETURN_SAVINGS_DURATION_ARRAY_START:
      return {
        ...state,
        returnSavingsDurationArray: {
          ...state.returnSavingsDurationArray,
          loadingOperator: true,
        },
      };
    case REQUEST_RETURN_SAVINGS_DURATION_ARRAY_ERROR:
      return {
        ...state,
        returnSavingsDurationArray: {
          ...state.returnSavingsDurationArray,
          operatorError: payload.error,
          success: false,
          loadingOperator: false,
        },
      };
    case REQUEST_RETURN_SAVINGS_DURATION_ARRAY_SUCCESS:
      return {
        ...state,
        returnSavingsDurationArray: {
          ...state.returnSavingsDurationArray,
          ...payload,
          success: true,
          operatorError: null,
          loadingOperator: false,
        },
      };
    case CLEAR_REQUEST_RETURN_SAVINGS_DURATION_ARRAY:
      return {
        ...state,
        returnSavingsDurationArray: {
          ...state.returnSavingsDurationArray,
          ...payload,
          loadingOperator: false,
          success: false,
          message: '',
          operatorError: null,
          operatorData: {},
        },
      };
    default:
      return null;
  }
};
