export const GET_RECENT_ACTIVE_CONTACTS_ERROR =
  'GET_RECENT_ACTIVE_CONTACTS_ERROR';
export const GET_RECENT_ACTIVE_CONTACTS_SUCCESS =
  'GET_RECENT_ACTIVE_CONTACTS_SUCCESS';
export const GET_RECENT_ACTIVE_CONTACTS_START =
  'GET_RECENT_ACTIVE_CONTACTS_START';

export const GET_ACTIVE_EXTERNAL_CONTACTS_ERROR =
  'GET_ACTIVE_EXTERNAL_CONTACTS_ERROR';
export const GET_ACTIVE_EXTERNAL_CONTACTS_SUCCESS =
  'GET_ACTIVE_EXTERNAL_CONTACTS_SUCCESS';
export const GET_ACTIVE_EXTERNAL_CONTACTS_START =
  'GET_ACTIVE_EXTERNAL_CONTACTS_START';

export const ADD_CONTACT_TO_RECENTS = 'ADD_CONTACT_TO_RECENTS';
