export default {
  savedContacts: {
    data: [],
    loading: false,
    error: null,
  },
  transferConfirmation: {
    data: {},
    loading: false,
    error: null,
  },
  transferMoney: {
    loading: false,
    data: null,
    error: null,
  },
  merchantPay: {
    loading: false,
    data: null,
    error: null,
  },
  purchaseCredit: {
    loading: false,
    data: null,
    error: null,
  },
  cardlessAtm: {
    loading: false,
    data: null,
    error: null,
  },
  payBills: {
    loading: false,
    data: null,
    error: null,
  },
};
