import {
  SAVINGS_CANCEL_CONFIRMATION_START,
  SAVINGS_CANCEL_CONFIRMATION_SUCCESS,
  SAVINGS_CANCEL_CONFIRMATION_ERROR,
  CLEAR_SAVINGS_CANCEL_CONFIRMATION,
} from 'constants/action-types/savings';

export default (state, { type, payload }) => {
  switch (type) {
    case SAVINGS_CANCEL_CONFIRMATION_START:
      return {
        ...state,
        savingsCancelConfirmation: {
          ...state.savingsCancelConfirmation,
          loading: true,
          error: null,
        },
      };
    case SAVINGS_CANCEL_CONFIRMATION_ERROR:
      return {
        ...state,
        savingsCancelConfirmation: {
          ...state.savingsCancelConfirmation,
          data: null,
          error: payload,
          loading: false,
        },
      };
    case SAVINGS_CANCEL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        savingsCancelConfirmation: {
          ...state.savingsCancelConfirmation,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case CLEAR_SAVINGS_CANCEL_CONFIRMATION:
      return {
        ...state,
        savingsCancelConfirmation: {
          ...state.savingsCancelConfirmation,
          ...payload,
          loading: false,
          error: null,
          data: null,
        },
      };
    default:
      return null;
  }
};
