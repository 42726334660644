import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';
import './index.scss';
import CustomModal from 'components/common/CustomModal';
import { CustomTag } from '../View';

const SavingPlanDetails = ({
  open,
  onClose,
  data,
  cardLabels,
  onOpenClaimPrematurePayout,
}) => {
  return (
    <CustomModal open={open} size={'tiny'}>
      <div className="container__column">
        <div className="form-box__item">
          <div className="form-box">
            <h3>
              {global.translate('Savings plan')}{' '}
              {`(${
                data?.Status === '0'
                  ? global.translate('ongoing')
                  : data?.Status === '1'
                  ? global.translate('closed')
                  : global.translate('canceled')
              })`}
            </h3>
            <div className="display-flex-row space-between">
              <div
                className="display-flex-column"
                style={{ alignSelf: 'center' }}
              >
                <span className="container-title">
                  {data?.Currency} {data?.Capital}
                </span>
              </div>

              {data?.Status === '0' && (
                <div>
                  <div className="alert-message-context">
                    <span>
                      {data?.RemainingDays}{' '}
                      {global.translate('remaining days')}
                    </span>
                  </div>
                </div>
              )}

              {data?.Status === '3' && (
                <CustomTag
                  title={'Canceled'}
                  className={'custom-tag-failed'}
                />
              )}

              {data?.Status === '1' && (
                <CustomTag
                  title={'Closed'}
                  className={'custom-tag-success'}
                />
              )}
            </div>

            <div className="display-flex-column content-box">
              <div className="display-flex-column">
                <div
                  className="display-flex-row"
                  style={{ padding: '8px' }}
                >
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Interest rate')}
                      {'(APR)'}
                    </div>
                    <span className="context-title">{data?.APR}</span>
                  </div>
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Penalty rate')}
                    </div>
                    <span className="context-title">
                      {data?.PenaltyRate} {!data?.PenaltyRate ?? '%'}
                    </span>
                  </div>
                </div>

                <div className="contain_divider"></div>

                <div
                  className="display-flex-row"
                  style={{ padding: '8px' }}
                >
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Amount')}
                    </div>
                    <span className="context-title">
                      {data?.Currency} {data?.Capital}
                    </span>
                  </div>

                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Cancellation date')}
                    </div>
                    <span className="context-title">
                      {['0', '1'].includes(data?.Status)
                        ? '-'
                        : data?.ClosingDate}
                    </span>
                  </div>
                </div>

                <div className="contain_divider"></div>

                <div
                  className="display-flex-row"
                  style={{ padding: '8px' }}
                >
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Estimated payment amount')}
                    </div>
                    <span className="context-title">
                      {data?.Currency} {data?.PaybackAmount}
                    </span>
                  </div>
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Earnings')}
                    </div>
                    <span className="context-title">
                      {data?.Currency} {data?.Earning}
                    </span>
                  </div>
                </div>

                <div className="contain_divider"></div>

                <div
                  className="display-flex-row"
                  style={{ padding: '8px' }}
                >
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Maturity date')}
                    </div>
                    <span className="context-title">
                      {moment(data?.ClosingDate).format('YYYY-MM-DD')}
                    </span>
                  </div>
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Transaction ID')}
                    </div>
                    <span className="context-title">
                      {data?.TransactionID}
                    </span>
                  </div>
                </div>

                <div className="contain_divider"></div>
                <div
                  className="display-flex-row"
                  style={{ padding: '8px' }}
                >
                  <div className="display-flex-column">
                    <div className="container-subtitle">
                      {global.translate('Penalty')}
                    </div>
                    <span className="context-title">
                      {data?.Currency} {data?.Penalty}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {data?.Status === '0' && (
              <>
                {data?.CanBeCancelled === 'YES' && (
                  <div className="claim-premature-btn">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onOpenClaimPrematurePayout();
                        onClose();
                      }}
                    >
                      <span style={{ color: '#1B66F8' }}>
                        {global.translate('Claim premature payout')}
                      </span>
                    </a>
                  </div>
                )}
              </>
            )}

            <div style={{ marginTop: '5px' }}>
              <Button onClick={onClose} className="save-btn">
                {global.translate('Close')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default SavingPlanDetails;
