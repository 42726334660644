import { lazy } from 'react';
export default {
  exact: true,
  name: 'duplicate-bulk-payment',
  protected: true,
  path: '/duplicate-bulk-payment',
  component: lazy(() =>
    import('containers/Organization/duplicateBulkPayment'),
  ),
};
