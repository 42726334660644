import CustomModal from 'components/common/CustomModal';
import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';

const ConfirmationModal = ({
  open,
  data,
  close,
  setOpenPinModal,
  cancelData,
  setOpenCancelSavingsPinModal,
}) => {
  return (
    <CustomModal open={open} size={'tiny'}>
      <div className="container__column">
        <div className="form-box__item">
          <h3>{global.translate('Summary')}</h3>
          {cancelData && (
            <div className="custom-modal__style-bottom">
              <div className="form-box">
                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Amount')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {cancelData?.BaseCurrency} {cancelData?.Amount}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Fees')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {cancelData?.BaseCurrency} {cancelData?.Fees ?? 0}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Earnings')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {cancelData?.BaseCurrency} {cancelData?.Earnings}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Penalty rate')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {cancelData?.PenaltyRate} %
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Penalty')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {cancelData?.BaseCurrency} {cancelData?.Penalty}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Total amount')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {cancelData?.BaseCurrency}{' '}
                    {cancelData?.TotalAmount}
                  </span>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <Button
                    onClick={close}
                    className="close-btn__style"
                  >
                    {global.translate('Cancel')}
                  </Button>

                  <Button
                    onClick={() => {
                      setOpenCancelSavingsPinModal(true);
                      close();
                    }}
                    className="save-btn"
                  >
                    {global.translate('Proceed')}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {data && (
            <div className="custom-modal__style-bottom">
              <div className="form-box">
                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Amount')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.BaseCurrency} {data?.Amount}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Taxes')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.BaseCurrency} {data?.Taxes ?? '0'}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Interest rate (APR)')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.APR} %
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Earnings')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.BaseCurrency} {data?.Earnings}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Penalty rate')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.PenaltyRate} %
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Minimum penalty')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.BaseCurrency} {data?.PenaltyMin}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Maximum penalty')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.BaseCurrency} {data?.PenalltyMax}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Maturity Date')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {moment(data?.PaybackDate).format('YYYY-MM-DD')}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Can be cancelled')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.CanBeCancelled}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Prorated payment')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.PayProrated}
                  </span>
                </div>

                <div className="div__separator"></div>
                <div className="form-box__items">
                  <span style={{ color: '#262D30' }}>
                    {global.translate('Total amount')}
                  </span>
                  <span
                    style={{ color: '#262D30', fontWeight: '600' }}
                  >
                    {data?.BaseCurrency} {data?.TotalAmount}
                  </span>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <Button
                    onClick={close}
                    className="close-btn__style"
                  >
                    {global.translate('Cancel')}
                  </Button>

                  <Button
                    onClick={() => {
                      setOpenPinModal(true);
                      close();
                    }}
                    className="save-btn"
                  >
                    {global.translate('Proceed')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
