import React from 'react';
import { useDispatch } from 'react-redux';
import { PAY_BILLS_ACTIONS_TYPES } from '../actionType';
import Response from '../useResponse';

export const initialValues = {
  selectedCountry: {},
  providers: {},
  wallets: {},
  selectedWallet: {},
  loading: false,
  error: false,
  open: false,
  size: undefined,
  openResultModal: false,
  openResult2Modal: false,
  getUser: {},
};

export const payBillsReducers = (state, action) => {
  switch (action.type) {
    case PAY_BILLS_ACTIONS_TYPES.FETCH_SELECTED_COUNTRY:
      const data = action.payload;
      return {
        ...state,
        selectedCountry: data?.countries?.filter(
          country =>
            country?.CountryCode === data?.operationCountry ||
            country?.CountryCode === data?.selectedCountry,
        )[0],
      };

    case PAY_BILLS_ACTIONS_TYPES.FETCH_LOCATED_USERS:
      return {
        ...state,
        getUser: action.payload,
      };

    case PAY_BILLS_ACTIONS_TYPES.FETCH_PROVIDERS:
      const providersData = action.payload;
      return {
        ...state,
        providers: providersData?.providers?.filter(
          provider =>
            provider.TargetType === providersData?.billsCategories &&
            provider.Available === 'YES',
        ),
      };

    case PAY_BILLS_ACTIONS_TYPES.SELECTED_WALLETS:
      const walletsData = action.payload;
      return {
        ...state,
        selectedWallet: walletsData?.filter(
          wallet => wallet.Default === 'YES',
        ),
      };

    case PAY_BILLS_ACTIONS_TYPES.REQUEST_GATEWAY_VERIFY_ACCOUNT:
      const requestVerification = action.payload;
      requestVerification.request(requestVerification.data)(
        requestVerification.dispatch,
      );

      return {
        ...state,
        success: true,
      };

    case 'close':
      return { open: false };

    case 'open':
      return { openResultModal: true, size: action.size };

    case 'closeResult2Modal':
      return { openResult2Modal: false };

    case 'openResult2Modal':
      return { openResult2Modal: true, size: action.size };

    default:
      return state;
  }
};
