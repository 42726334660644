import {
  EXECUTE_SAVINGS_PLAN_START,
  EXECUTE_SAVINGS_PLAN_SUCCESS,
  EXECUTE_SAVINGS_PLAN_ERROR,
  CLEAR_EXECUTE_SAVINGS_PLAN,
} from 'constants/action-types/savings';

export default (state, { type, payload }) => {
  switch (type) {
    case EXECUTE_SAVINGS_PLAN_START:
      return {
        ...state,
        executeSavingsPlan: {
          ...state.executeSavingsPlan,
          loading: true,
        },
      };
    case EXECUTE_SAVINGS_PLAN_ERROR:
      return {
        ...state,
        executeSavingsPlan: {
          ...state.executeSavingsPlan,
          error: payload.error,
          success: false,
          loading: false,
        },
      };
    case EXECUTE_SAVINGS_PLAN_SUCCESS:
      return {
        ...state,
        executeSavingsPlan: {
          ...state.executeSavingsPlan,
          ...payload,
          success: true,
          error: null,
          loading: false,
        },
      };
    case CLEAR_EXECUTE_SAVINGS_PLAN:
      return {
        ...state,
        executeSavingsPlan: {
          ...state.executeSavingsPlan,
          loading: false,
          error: null,
          data: null,
        },
      };
    default:
      return null;
  }
};
