import {
  GET_SAVINGS_LIST_START,
  GET_SAVINGS_LIST_SUCCESS,
  GET_SAVINGS_LIST_ERROR,
  CLEAR_GET_SAVINGS_LIST,
} from 'constants/action-types/savings';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_SAVINGS_LIST_START:
      return {
        ...state,
        savingsList: {
          ...state.savingsList,
          loading: true,
        },
      };
    case GET_SAVINGS_LIST_ERROR:
      return {
        ...state,
        savingsList: {
          ...state.savingsList,
          error: payload.error,
          success: false,
          loading: false,
        },
      };
    case GET_SAVINGS_LIST_SUCCESS:
      return {
        ...state,
        savingsList: {
          ...state.savingsList,
          ...payload,
          success: true,
          error: null,
          loading: false,
        },
      };
    case CLEAR_GET_SAVINGS_LIST:
      return {
        ...state,
        savingsList: {
          ...state.savingsList,
          ...payload,
          loading: false,
          success: false,
          message: '',
          error: null,
          data: {},
        },
      };
    default:
      return null;
  }
};
