import { lazy } from 'react';

export default {
  exact: false,
  name: 'cashback',
  protected: true,
  path: '/cashback',
  component: lazy(() =>
    import('containers/GatewayCashout/CashoutForMyself'),
  ),
};
