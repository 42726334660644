import { toast } from 'react-toastify';

import {
  SEND_MONEY_TO_BANK_FAILURE,
  SEND_MONEY_TO_BANK_START,
  SEND_MONEY_TO_BANK_SUCCESS,
  CLEAR_SEND_MONEY_TO_BANK,
} from 'constants/action-types/walletsAndBanks/bankAccounts';
import apiAction from 'helpers/apiAction';

export default (data, successCallback) => dispatch =>
  dispatch(
    apiAction({
      method: 'post',
      url: '/WalletToBankAccount',
      data,
      onStart: () => dispatch =>
        dispatch({ type: SEND_MONEY_TO_BANK_START }),
      onSuccess: data => dispatch => {
        const res = Array.isArray(data) ? data[0] : data ?? {};
        toast.success(global.translate(res?.Description));
        dispatch({
          type: SEND_MONEY_TO_BANK_SUCCESS,
          payload: { ...res, success: res.Result === 'Success' },
        });
        if (typeof successCallback === 'function') {
          successCallback();
        }
      },
      onFailure: error => dispatch => {
        const err = Array.isArray(error) ? error[0] : error ?? {};
        toast.error(global.translate(err.Description));
        dispatch({
          type: SEND_MONEY_TO_BANK_FAILURE,
          payload: { error: err },
        });
      },
    }),
  );

export const clearSendMoneyToBank = () => dispatch => {
  return dispatch({
    type: CLEAR_SEND_MONEY_TO_BANK,
  });
};
