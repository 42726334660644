import initialState from 'redux/initial-states/gimac';
import getSavedAccountContacts from './getSavedAccountContacts';
import transferConfirmation from './transferConfirmation';
import transferMoney from './transferMoney';
import merchantPay from './merchantPay';
import cardlessAtm from './cardlessAtm';
import payBills from './payBills';
import purchaseCredit from './purchaseCredit';

export default (state = initialState, action = {}) => ({
  ...state,
  ...getSavedAccountContacts(state, action),
  ...transferConfirmation(state, action),
  ...transferMoney(state, action),
  ...merchantPay(state, action),
  ...payBills(state, action),
  ...cardlessAtm(state, action),
  ...purchaseCredit(state, action),
});
