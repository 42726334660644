export default {
  providersCountries: {
    data: null,
    loading: false,
    error: null,
  },
  providersList: {
    data: null,
    loading: false,
    error: null,
  },
};
