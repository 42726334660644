export const GET_SERVICE_CATEGORIES_START =
  'GET_SERVICE_CATEGORIES_START';
export const GET_SERVICE_CATEGORIES_SUCCESS =
  'GET_SERVICE_CATEGORIES_SUCCESS';
export const GET_SERVICE_CATEGORIES_ERROR =
  'GET_SERVICE_CATEGORIES_ERROR';

export const CREATE_SERVICE_START = 'CREATE_SERVICE_START';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_ERROR = 'CREATE_SERVICE_ERROR';

export const LIKE_SERVICE_START = 'LIKE_SERVICE_START';
export const LIKE_SERVICE_SUCCESS = 'LIKE_SERVICE_SUCCESS';
export const LIKE_SERVICE_ERROR = 'LIKE_SERVICE_ERROR';

export const BOOKMARK_SERVICE_START = 'BOOKMARK_SERVICE_START';
export const BOOKMARK_SERVICE_SUCCESS = 'BOOKMARK_SERVICE_SUCCESS';
export const BOOKMARK_SERVICE_ERROR = 'BOOKMARK_SERVICE_ERROR';

export const GET_BOOKMARKED_SERVICE_START =
  'GET_BOOKMARKED_SERVICE_START';
export const GET_BOOKMARKED_SERVICE_SUCCESS =
  'GET_BOOKMARKED_SERVICE_SUCCESS';
export const GET_BOOKMARKED_SERVICE_ERROR =
  'GET_BOOKMARKED_SERVICE_ERROR';

export const UN_BOOKMARK_SERVICE_START = 'UN_BOOKMARK_SERVICE_START';

export const UN_BOOKMARK_SERVICE_SUCCESS =
  'UN_BOOKMARK_SERVICE_SUCCESS';
export const UN_BOOKMARK_SERVICE_ERROR = 'UN_BOOKMARK_SERVICE_ERROR';

export const RATE_SERVICE_START = 'RATE_SERVICE_START';
export const RATE_SERVICE_SUCCESS = 'RATE_SERVICE_SUCCESS';
export const RATE_SERVICE_ERROR = 'RATE_SERVICE_ERROR';

export const DISLIKE_SERVICE_START = 'DISLIKE_SERVICE_START';
export const DISLIKE_SERVICE_SUCCESS = 'DISLIKE_SERVICE_SUCCESS';
export const DISLIKE_SERVICE_ERROR = 'DISLIKE_SERVICE_ERROR';

export const DELETE_SERVICE_COMMENT_START =
  'DELETE_SERVICE_COMMENT_START';
export const DELETE_SERVICE_COMMENT_SUCCESS =
  'DELETE_SERVICE_COMMENT_SUCCESS';
export const DELETE_SERVICE_COMMENT_ERROR =
  'DELETE_SERVICE_COMMENT_ERROR';
export const REPORT_SERVICE_OR_COMMENT_START =
  'REPORT_SERVICE_OR_COMMENT_START';
export const REPORT_SERVICE_OR_COMMENT_SUCCESS =
  'REPORT_SERVICE_OR_COMMENT_SUCCESS';
export const REPORT_SERVICE_OR_COMMENT_ERROR =
  'REPORT_SERVICE_OR_COMMENT_ERROR';
export const CLEAR_REPORT_SERVICE_OR_COMMENT =
  'CLEAR_REPORT_SERVICE_OR_COMMENT';

export const OPEN_CREATE_SERVICE_MODAL = 'OPEN_CREATE_SERVICE_MODAL';
export const CLOSE_CREATE_SERVICE_MODAL =
  'CLOSE_CREATE_SERVICE_MODAL';

export const GET_SERVICES_LIST_START = 'GET_SERVICES_LIST_START';
export const GET_SERVICES_LIST_SUCCESS = 'GET_SERVICES_LIST_SUCCESS';
export const GET_SERVICES_LIST_ERROR = 'GET_SERVICES_LIST_ERROR';

export const GET_MY_SERVICES_START = 'GET_MY_SERVICES_START';
export const GET_MY_SERVICES_SUCCESS = 'GET_MY_SERVICES_SUCCESS';
export const GET_MY_SERVICES_ERROR = 'GET_MY_SERVICES_ERROR';

export const GET_SERVICE_START = 'GET_SERVICE_START';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR';

export const GET_RELATED_SERVICES_START =
  'GET_RELATED_SERVICES_START';
export const GET_RELATED_SERVICES_SUCCESS =
  'GET_RELATED_SERVICES_SUCCESS';
export const GET_RELATED_SERVICES_ERROR =
  'GET_RELATED_SERVICES_ERROR';

export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_ERROR = 'DELETE_SERVICE_ERROR';
export const CLEAR_DELETE_SERVICE = 'CLEAR_DELETE_SERVICE';

export const SET_SERVICE_STATUS_START = 'SET_SERVICE_STATUS_START';
export const SET_SERVICE_STATUS_SUCCESS =
  'SET_SERVICE_STATUS_SUCCESS';
export const SET_SERVICE_STATUS_ERROR = 'SET_SERVICE_STATUS_ERROR';
export const CLEAR_SERVICE_UPDATES = 'CLEAR_SERVICE_UPDATES';

export const UPDATE_SERVICE_PRICING_START =
  'UPDATE_SERVICE_PRICING_START';
export const UPDATE_SERVICE_PRICING_SUCCESS =
  'UPDATE_SERVICE_PRICING_SUCCESS';
export const UPDATE_SERVICE_PRICING_ERROR =
  'UPDATE_SERVICE_PRICING_ERROR';

export const ADD_COMMENT_TO_SERVICE_START =
  'ADD_COMMENT_TO_SERVICE_START';
export const ADD_COMMENT_TO_SERVICE_SUCCESS =
  'ADD_COMMENT_TO_SERVICE_SUCCESS';
export const ADD_COMMENT_TO_SERVICE_ERROR =
  'ADD_COMMENT_TO_SERVICE_ERROR';

export const SEARCH_PEER_SERVICES_START =
  'SEARCH_PEER_SERVICES_START';
export const SEARCH_PEER_SERVICES_SUCCESS =
  'SEARCH_PEER_SERVICES_SUCCESS';
export const SEARCH_PEER_SERVICES_ERROR =
  'SEARCH_PEER_SERVICES_ERROR';

export const OPEN_EDIT_PRICING_SERVICE_MODAL =
  'OPEN_EDIT_PRICING_SERVICE_MODAL';

export const OPEN_DELETE_SERVICE_MODAL = 'OPEN_DELETE_SERVICE_MODAL';

export const OPEN_REPORT_SERVICE_COMMENT_MODAL =
  'OPEN_REPORT_SERVICE_COMMENT_MODAL';
export const SET_PEER_SERVICE = 'SET_PEER_SERVICE';

export const GET_SERVICE_LINK_PREVIEW_START =
  'GET_SERVICE_LINK_PREVIEW_START';

export const GET_SERVICE_LINK_PREVIEW_SUCCESS =
  'GET_SERVICE_LINK_PREVIEW_SUCCESS';

export const GET_SERVICE_LINK_PREVIEW_ERROR =
  'GET_SERVICE_LINK_PREVIEW_ERROR';

export const TOGGLE_PEER_SERVICES_MOBILE_VIEW =
  'TOGGLE_PEER_SERVICES_MOBILE_VIEW';

export const TOGGLE_DESKTOP_NAVBAR_FIXED =
  'TOGGLE_DESKTOP_NAVBAR_FIXED';
