import payBills from './payBill';
import transferMoney from './transferMoney';
import merchantPay from './merchantPay';
import purchaseCredit from './purchaseCredit';
import cardlessAtm from './cardlessAtm';

export default [
  payBills,
  transferMoney,
  merchantPay,
  purchaseCredit,
  cardlessAtm,
];
