import React, { useEffect, useState } from 'react';
import PinCodeForm from '../../../common/PinCodeForm/index';
import { Button } from 'semantic-ui-react';

const ConfirmationPage = props => {
  const {
    transactionConfirmationData,
    confirmTransactionPin,
    setConfirmTransactionPin,
    setCurrentPage,
    moveFundsToM2UWallet,
    setGetPin,
    loading,
    currentPage,
    option,
    sendMoneyToYourBankAccount,
    sendMoneyToMobileOperator,
    sendMoneyToSomeElseBankAccount,
    location
  } = props;

  const [submitBtn, setSubmitBtn] = useState(true);
  const onChange = ({ target: { name, value }, input }) => {
    setConfirmTransactionPin({
      ...confirmTransactionPin,
      [name]: value,
    });
  };

  useEffect(() => {
    if (confirmTransactionPin) {
      const {
        digit0,
        digit1,
        digit2,
        digit3,
        digit4,
        digit5,
      } = confirmTransactionPin;
      const value = `${digit0 || ''}${digit1 || ''}${digit2 ||
        ''}${digit3 || ''}${digit4 || ''}${digit5 || ''}`;
      if (value.length === 6) {
        setSubmitBtn(false);
        setGetPin(value);
      }
    }
  }, [confirmTransactionPin]);

  return (
    <>
      {transactionConfirmationData &&
        transactionConfirmationData?.map(item => {
          return (
            <>
              <div className="item-decider">
                <h3
                  className="page-title"
                  style={{ fontWeight: '700' }}
                >
                  {global.translate('Summary')}
                </h3>
                <div className="item-decider__box">
                  <span className="left">
                    {global.translate('Amount to be sent')}
                  </span>
                  <span className="right">
                    {item?.AmountToBeSent}
                  </span>
                </div>
                <div className="item-decider__box">
                  <span className="left">
                    {global.translate('Fees')}
                  </span>
                  <span className="right">{item?.Fees}</span>
                </div>
                <div className="item-decider__box">
                  <span className="left">
                    {global.translate('Exchange fees')}
                  </span>
                  <span className="right">{item?.ExchangeFees}</span>
                </div>
                <div className="item-decider__box">
                  <span className="left">
                    {global.translate('Exchange rate')}
                  </span>
                  <span className="right">{item?.ExchangeRate}</span>
                </div>
                <div className="item-decider__box">
                  <span className="left">
                    {global.translate('Taxes')}
                  </span>
                  <span className="right">{item?.Taxes}</span>
                </div>
                <div className="item-decider__box">
                  <span className="left">
                    {global.translate('Total amount')}
                  </span>
                  <span className="right">{item?.TotalAmount}</span>
                </div>
                <div style={{ marginTop: '30px' }}>
                  <span style={{ fontWeight: '600' }}>PIN:</span>
                  <PinCodeForm
                    label={global.translate('Enter PIN to confirm')}
                    onChange={e => onChange({ ...e, input: 'pin' })}
                  />
                </div>
              </div>
              <div className="bottom-transition__box">
                <Button
                  id="btn-back"
                  onClick={() => setCurrentPage(option)}
                >
                  {global.translate('Cancel')}
                </Button>
                <Button
                  disabled={submitBtn}
                  loading={loading}
                  onClick={() => {
                    if (option === 1) moveFundsToM2UWallet();
                    if (option === 2) sendMoneyToYourBankAccount();
                    if (option === 3) sendMoneyToMobileOperator();
                    if (option === 5)
                      sendMoneyToSomeElseBankAccount();
                  }}
                >
                  {global.translate('Confirm')}
                </Button>
              </div>
            </>
          );
        })}
    </>
  );
};

export default ConfirmationPage;
