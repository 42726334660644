import {
  GET_MERCHANT_TYPE_LIST_ERROR,
  GET_MERCHANT_TYPE_LIST_START,
  GET_MERCHANT_TYPE_LIST_SUCCESS,
} from 'constants/action-types/stores/getMerchantTypeList';

  
export default (state, { type, payload }) => {
    switch (type) {
      case GET_MERCHANT_TYPE_LIST_START:
        return {
          ...state,
          getMerchantTypeList: {
            ...state.getMerchantTypeList,
            loading: true,
          },
        };
      case GET_MERCHANT_TYPE_LIST_ERROR:
        return {
          ...state,
          getMerchantTypeList: {
            ...state.getMerchantTypeList,
            error: payload.error,
            loading: false,
          },
        };
      case GET_MERCHANT_TYPE_LIST_SUCCESS:
        return {
          ...state,
          getMerchantTypeList: {
            ...state.getMerchantTypeList,
            ...payload,
            success: true,
            loading: false,
            error: null,
          },
        };
      default:
        return null;
    }
  };
  