import React from 'react';

export const ItemWithFlag = ({ item }) => {
  return (
    <div className="item-with-flag">
      <img src={item?.Flag} />
      <span>{item?.CountryName}</span>
    </div>
  );
};
