import NewPayBills from 'components/nPayBills';
import BillsCategoriesContainer from 'containers/PayBills/billsCategories';

export default {
  exact: true,
  name: 'billsCategories',
  protected: true,
  path: '/pay-bills/:name',
  component: BillsCategoriesContainer,
};
