/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  BULK_PAYMENT_CALCULATION_IN_PROGRESS,
  BULK_PAYMENT_CALCULATION_DONE,
} from 'constants/events/bulkPayment';
import socketIOClient from 'services/socketIO';
import isAppDisplayedInWebView from 'helpers/isAppDisplayedInWebView';
import bulkPayment from 'redux/actions/users/bulkPayment';

export default () => {
  const { userData: { data } = {} } = useSelector(({ user }) => user);
  const _dispatch = useDispatch();
  const dispatch = ({ type, payload }) => {
    delete payload.onStart;
    delete payload.onFailure;
    delete payload.onEnd;
    const action = {
      type,
      payload,
    };
    return _dispatch(action);
  };

  useEffect(() => {
    if (data && data.PID) {
      socketIOClient.off(BULK_PAYMENT_CALCULATION_IN_PROGRESS);
      socketIOClient.on(
        BULK_PAYMENT_CALCULATION_IN_PROGRESS,
        notification => {
          const { message } = notification || {};
          if (!isAppDisplayedInWebView()) {
            toast.success(message);
          }
        },
      );
    }
    return () => {
      socketIOClient.off(BULK_PAYMENT_CALCULATION_IN_PROGRESS);
    };
  }, [data && data.PID]);

  useEffect(() => {
    if (data && data.PID) {
      socketIOClient.off(BULK_PAYMENT_CALCULATION_DONE);
      socketIOClient.on(
        BULK_PAYMENT_CALCULATION_DONE,
        notification => {
          bulkPayment(
            dispatch({
              type: BULK_PAYMENT_CALCULATION_DONE,
              payload: notification,
            }),
          );
          const { message } = notification || {};
          if (!isAppDisplayedInWebView()) {
            toast.success(message);
          }
        },
      );
    }
    return () => {
      socketIOClient.off(BULK_PAYMENT_CALCULATION_DONE);
    };
  }, [data && data.PID]);
};
