export const CONFIRM_TRANSACTION_ERROR = 'CONFIRM_TRANSACTION_ERROR';
export const CONFIRM_TRANSACTION_SUCCESS =
  'CONFIRM_TRANSACTION_SUCCESS';
export const CONFIRM_TRANSACTION_START = 'CONFIRM_TRANSACTION_START';
export const MOVE_FUNDS_ERROR = 'MOVE_FUNDS_ERROR';
export const MOVE_FUNDS_SUCCESS = 'MOVE_FUNDS_SUCCESS';
export const MOVE_FUNDS_START = 'MOVE_FUNDS_START';
export const CLEAR_TRANSACTION_INFO = 'CLEAR_TRANSACTION_INFO';
export const CLEAR_MOVE_FUNDS_ERRORS = 'CLEAR_MOVE_FUNDS_ERRORS';
export const MODIFY_CASH_START = 'MODIFY_CASH_START';
export const MODIFY_CASH_SUCCESS = 'MODIFY_CASH_SUCCESS';
export const MODIFY_CASH_ERROR = 'MODIFY_CASH_ERROR';
export const CLEAR_MODIFY_CASH_ERRORS = 'CLEAR_MODIFY_CASH_ERRORS';

export const TRANSFER_TO_OTHERS_ERROR = 'TRANSFER_TO_OTHERS_ERROR ';
export const TRANSFER_TO_OTHERS_START = 'TRANSFER_TO_OTHERS_START ';
export const TRANSFER_TO_OTHERS_SUCCESS =
  'TRANSFER_TO_OTHERS_SUCCESS ';
export const CLEAR_TRANSFER_TO_OTHERS_ERRORS =
  'CLEAR_TRANSFER_TO_OTHERS_ERRORS';

export const SEND_FUNDS_TO_GATEWAY_ERROR =
  'SEND_FUNDS_TO_GATEWAY_ERROR ';
export const SEND_FUNDS_TO_GATEWAY_START =
  'SEND_FUNDS_TO_GATEWAY_START ';
export const SEND_FUNDS_TO_GATEWAY_SUCCESS =
  'SEND_FUNDS_TO_GATEWAY_SUCCESS ';
export const CLEAR_SEND_FUNDS_TO_GATEWAY_STORE =
  'CLEAR_SEND_FUNDS_TO_GATEWAY_STORE';

export const GATEWAY_TRANSFER_CONFIRMATION_ERROR =
  'GATEWAY_TRANSFER_CONFIRMATION_ERROR ';
export const GATEWAY_TRANSFER_CONFIRMATION_START =
  'GATEWAY_TRANSFER_CONFIRMATION_START ';
export const GATEWAY_TRANSFER_CONFIRMATION_SUCCESS =
  'GATEWAY_TRANSFER_CONFIRMATION_SUCCESS ';

export const CLEAR_GATEWAY_TRANSFER_CONFIRMATION_SUCCESS =
  'CLEAR_GATEWAY_TRANSFER_CONFIRMATION_SUCCESS ';

export const TRANSFER_FUNDS_INTERNAL_ERROR =
  'TRANSFER_FUNDS_INTERNAL_ERROR ';
export const TRANSFER_FUNDS_INTERNAL_START =
  'TRANSFER_FUNDS_INTERNAL_START ';
export const TRANSFER_FUNDS_INTERNAL_SUCCESS =
  'TRANSFER_FUNDS_INTERNAL_SUCCESS ';
export const TRANSFER_FUNDS_INTERNAL_CLEAR =
  'TRANSFER_FUNDS_INTERNAL_CLEAR ';

export const REQUEST_CASHBACK_ERROR = 'REQUEST_CASHBACK_ERROR ';
export const REQUEST_CASHBACK_START = 'REQUEST_CASHBACK_START';
export const REQUEST_CASHBACK_SUCCESS = 'REQUEST_CASHBACK_SUCCESS';
export const REQUEST_CASHBACK_CLEAR = 'REQUEST_CASHBACK_CLEAR';
export const CLEAR_REQUEST_CASHBACK = 'CLEAR_REQUEST_CASHBACK';

export const ADD_MONEY_WITH_PAYPAL_ERROR =
  'ADD_MONEY_WITH_PAYPAL_ERROR ';
export const ADD_MONEY_WITH_PAYPAL_START =
  'ADD_MONEY_WITH_PAYPAL_START';
export const ADD_MONEY_WITH_PAYPAL_SUCCESS =
  'ADD_MONEY_WITH_PAYPAL_SUCCESS';
export const CLEAR_ADD_MONEY_WITH_PAYPAL =
  'CLEAR_ADD_MONEY_WITH_PAYPAL';
