// TransferFundsInternal
import { toast } from 'react-toastify';
import {
  TRANSFER_FUNDS_INTERNAL_ERROR,
  TRANSFER_FUNDS_INTERNAL_START,
  TRANSFER_FUNDS_INTERNAL_SUCCESS,
  TRANSFER_FUNDS_INTERNAL_CLEAR,
} from 'constants/action-types/moneyTransfer';
import apiAction from 'helpers/apiAction';

export default data => dispatch => {
  return dispatch(
    apiAction({
      method: 'post',
      url: '/TransferFundsInternal',
      data,
      onStart: () => dispatch =>
        dispatch({
          type: TRANSFER_FUNDS_INTERNAL_START,
          payload: data,
        }),
      onSuccess: data => dispatch => {
        toast.success(global.translate(data[0].Description));
        return dispatch({
          type: TRANSFER_FUNDS_INTERNAL_SUCCESS,
          payload: data,
        });
      },
      onFailure: error => dispatch => {
        toast.error(global.translate(error[0].Description));
        return dispatch({
          type: TRANSFER_FUNDS_INTERNAL_ERROR,
          payload: error,
        });
      },
    }),
  );
};

export const clearTransferFundsInternalStore = () => dispatch => {
  dispatch({
    type: TRANSFER_FUNDS_INTERNAL_CLEAR,
  });
};
