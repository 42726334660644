export const GIMAC_MOBILE_TRANSFER = '30';
export const GIMAC_ACCOUNT_TRANSFER = '36';
export const GIMAC_ATM = '31';
export const GIMAC_MERCHANT = '32';
export const GIMAC_INVOICE_POSTPAID = '33';
export const GIMAC_INVOICE_PREPAID = '34';
export const GIMAC_TOP_UP = '35';

export const MNO_OPERATOR_TYPE = '21';
export const BANK_OPERATOR_TYPE = '4';
