/**
 * @param {object} error
 * @returns {boolean} true if the token has expired otherwise false
 */
export default error => {
  try {
    let hasTokenExpired = false;
    if (Array.isArray(error) && error.length) {
      error.forEach(err => {
        if (
          typeof err === 'object' &&
          err.TokenVerified &&
          String(err.TokenVerified || '').toLowerCase() === 'false'
        ) {
          hasTokenExpired = true;
        }
      });
    } else if (error && Object.keys(error).length) {
      if (
        typeof error === 'object' &&
        error.TokenVerified &&
        String(error.TokenVerified || '').toLowerCase() === 'false'
      ) {
        hasTokenExpired = true;
      }
    }
    return hasTokenExpired;
  } catch (e) {
    return false;
  }
};
