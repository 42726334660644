import {
  OPERATION_FAILED,
  CLEAR_FAILED_OPERATION,
} from 'constants/events/operations';

export default (state, { type, payload }) => {
  switch (type) {
    case OPERATION_FAILED: {
      return {
        ...state,
        operation: {
          ...state.operation,
          data: payload,
        },
      };
    }

    case CLEAR_FAILED_OPERATION: {
      return {
        ...state,
        operation: {
          ...state.operation,
          data: null,
        },
      };
    }

    default:
      return null;
  }
};
