import {
  EXECUTE_SAVINGS_PLAN_START,
  EXECUTE_SAVINGS_PLAN_SUCCESS,
  EXECUTE_SAVINGS_PLAN_ERROR,
  CLEAR_EXECUTE_SAVINGS_PLAN,
} from 'constants/action-types/savings';
import apiAction from 'helpers/apiAction';

export default data => dispatch =>
  dispatch(
    apiAction({
      method: 'post',
      url: '/ExecuteSavingsPlan',
      data,
      onStart: () => dispatch =>
        dispatch({
          type: EXECUTE_SAVINGS_PLAN_START,
        }),
      onSuccess: res => dispatch => {
        const payload = Array.isArray(res) ? res[0] : res || {};
        return dispatch({
          type: EXECUTE_SAVINGS_PLAN_SUCCESS,
          payload: {
            data: payload,
          },
        });
      },
      onFailure: error => dispatch => {
        return dispatch({
          type: EXECUTE_SAVINGS_PLAN_ERROR,
          payload: {
            error: error[0],
          },
        });
      },
    }),
  );

export const clearExecuteSavingsPlan = () => dispatch => {
  return dispatch({
    type: CLEAR_EXECUTE_SAVINGS_PLAN,
  });
};
