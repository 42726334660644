import { BULK_PAYMENT_CALCULATION_DONE } from 'constants/events/bulkPayment';

export default (state, { type, payload }) => {
  switch (type) {
    case BULK_PAYMENT_CALCULATION_DONE: {
      return {
        ...state,
        bulkPayment: {
          ...state.bulkPayment,
          data: payload,
        },
      };
    }

    default:
      return null;
  }
};
