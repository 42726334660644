// GET BANK LIST
export const GET_BANK_LIST_START = 'GET_BANK_LIST_START';
export const GET_BANK_LIST_SUCCESS = 'GET_BANK_LIST_SUCCESS';
export const GET_BANK_LIST_FAILURE = 'GET_BANK_LIST_FAILURE';

export const GET_LINKED_BANK_ACCOUNTS_START =
  'GET_LINKED_BANK_ACCOUNTS_START';
export const GET_LINKED_BANK_ACCOUNTS_SUCCESS =
  'GET_LINKED_BANK_ACCOUNTS_SUCCESS';
export const GET_LINKED_BANK_ACCOUNTS_FAILURE =
  'GET_LINKED_BANK_ACCOUNTS_FAILURE';

// LINK BANK ACCOUNT

export const LINK_BANK_ACCOUNT_REQUEST_START =
  'LINK_BANK_ACCOUNT_REQUEST_START';
export const LINK_BANK_ACCOUNT_REQUEST_SUCCESS =
  'LINK_BANK_ACCOUNT_REQUEST_SUCCESS';
export const LINK_BANK_ACCOUNT_REQUEST_FAILURE =
  'LINK_BANK_ACCOUNT_REQUEST_FAILURE';
export const CLEAR_LINK_ACCOUNT_REQUEST =
  'CLEAR_LINK_ACCOUNT_REQUEST';

export const SELF_LINK_BANK_ACCOUNT_START =
  'SELF_LINK_BANK_ACCOUNT_START';
export const SELF_LINK_BANK_ACCOUNT_SUCCESS =
  'SELF_LINK_BANK_ACCOUNT_SUCCESS';
export const SELF_LINK_BANK_ACCOUNT_FAILURE =
  'SELF_LINK_BANK_ACCOUNT_FAILURE';
export const CLEAR_SELF_LINK_BANK = 'CLEAR_SELF_LINK_BANK';

export const UPDATE_LINKED_BANK_LIST = 'UPDATE_LINKED_BANK_LIST';
export const REMOVE_UNLINKED_ACCOUNT = 'REMOVE_UNLINKED_ACCOUNT';
// UNLINK bank account
export const UNLINK_BANK_START = 'UNLINK_BANK_START';
export const UNLINK_BANK_SUCCESS = 'UNLINK_BANK_SUCCESS';
export const UNLINK_BANK_FAILURE = 'UNLINK_BANK_FAILURE';
export const CLEAR_UNLINK_BANK = 'CLEAR_UNLINK_BANK';

// USER LINK BANK ACCOUNT

export const LINK_BANK_ACCOUNT_START = 'LINK_BANK_ACCOUNT_START';
export const LINK_BANK_ACCOUNT_SUCCESS = 'LINK_BANK_ACCOUNT_SUCCESS';
export const LINK_BANK_ACCOUNT_FAILURE = 'LINK_BANK_ACCOUNT_FAILURE';

// FROM BANK TO WALLET
export const ADD_MONEY_TO_WALLET_START = 'ADD_MONEY_TO_WALLET_START';
export const ADD_MONEY_TO_WALLET_SUCCESS =
  'ADD_MONEY_TO_WALLET_SUCCESS';
export const ADD_MONEY_TO_WALLET_FAILURE =
  'ADD_MONEY_TO_WALLET_FAILURE';

// FROM WALLET TO BANK ACCOUNT
export const SEND_MONEY_TO_BANK_FAILURE =
  'SEND_MONEY_TO_BANK_FAILURE';
export const SEND_MONEY_TO_BANK_START = 'SEND_MONEY_TO_BANK_START';
export const SEND_MONEY_TO_BANK_SUCCESS =
  'SEND_MONEY_TO_BANK_SUCCESS';
export const CLEAR_ADD_MONEY_TO_WALLET = 'CLEAR_ADD_MONEY_TO_WALLET';
export const CLEAR_SEND_MONEY_TO_BANK = 'CLEAR_SEND_MONEY_TO_BANK';
