import {
  ADD_MONEY_WITH_PAYPAL_START,
  ADD_MONEY_WITH_PAYPAL_SUCCESS,
  ADD_MONEY_WITH_PAYPAL_ERROR,
  CLEAR_ADD_MONEY_WITH_PAYPAL,
} from 'constants/action-types/moneyTransfer';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_MONEY_WITH_PAYPAL_START:
      return {
        ...state,
        addMoneyFromPayPal1: {
          ...state.addMoneyFromPayPal1,
          loading: true,
          error: null,
        },
      };
    case ADD_MONEY_WITH_PAYPAL_ERROR:
      return {
        ...state,
        addMoneyFromPayPal1: {
          ...state.addMoneyFromPayPal1,
          error: payload,
          loading: false,
        },
      };
    case ADD_MONEY_WITH_PAYPAL_SUCCESS:
      return {
        ...state,
        addMoneyFromPayPal1: {
          ...state.addMoneyFromPayPal1,
          error: null,
          loading: false,
          data: payload,
        },
      };
    case CLEAR_ADD_MONEY_WITH_PAYPAL: {
      return {
        ...state,
        addMoneyFromPayPal1: {
          ...state.addMoneyFromPayPal1,
          error: null,
          loading: false,
          data: null,
        },
      };
    }
    default:
      return null;
  }
};
