import { storageKeyName } from 'constants/general';

const browserSignature = _ => {
  const windowObj = window || global;

  const windowObjCount = _ => {
    const keys = [];
    for (let i in windowObj) {
      keys.push(i);
    }
    return keys.length.toString(36);
  };

  const pad = (str, size) => {
    return (new Array(size + 1).join('0') + str).slice(-size);
  };

  const navi = (
    navigator.mimeTypes.length + navigator.userAgent.length
  ).toString(36);
  const padString = pad(navi + windowObjCount(), 4);

  const width = windowObj.screen.width.toString(36);
  const height = windowObj.screen.height.toString(36);
  const availWidth = windowObj.screen.availWidth.toString(36);
  const availHeight = windowObj.screen.availHeight.toString(36);
  const colorDepth = windowObj.screen.colorDepth.toString(36);
  const pixelDepth = windowObj.screen.pixelDepth.toString(36);

  return btoa(
    padString +
      width +
      height +
      availWidth +
      availHeight +
      colorDepth +
      pixelDepth,
  );
};

export const browserUniqueSignature = () => {
  const savedData = localStorage.getItem(storageKeyName);
  if (!savedData) {
    localStorage.setItem(storageKeyName, browserSignature());
  } else {
  }
};
