export const GET_TRANSACTIONS_OVERVIEW_START =
  'GET_TRANSACTIONS_OVERVIEW_START';
export const GET_TRANSACTIONS_OVERVIEW_ERROR =
  'GET_TRANSACTIONS_OVERVIEW_ERROR';
export const GET_TRANSACTIONS_OVERVIEW_SUCCESS =
  'GET_TRANSACTIONS_OVERVIEW_SUCCESS';

export const TRANSACTIONS_OVERVIEW_WITH_CONTACT_START =
  'TRANSACTIONS_OVERVIEW_WITH_CONTACT_START';
export const TRANSACTIONS_OVERVIEW_WITH_CONTACT_ERROR =
  'TRANSACTIONS_OVERVIEW_WITH_CONTACT_ERROR';
export const TRANSACTIONS_OVERVIEW_WITH_CONTACT_SUCCESS =
  'TRANSACTIONS_OVERVIEW_WITH_CONTACT_SUCCESS';
