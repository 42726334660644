import { lazy } from 'react';

export default {
  exact: true,
  name: 'bulk-payment-details',
  protected: true,
  path: '/bulk-payment-details',
  component: lazy(() =>
    import('containers/Organization/bulkPaymentDetails'),
  ),
};
