import initialState from 'redux/initial-states/organization';
import addBulkPaymentRecipient from './addBulkPaymentRecipient';
import createBulkPayment from './createBulkPayment';
import getBulkPaymentDetails from './getBulkPaymentDetails';
import bulkPaymentList from './getBulkPaymentList';
import duplicateBulkPayment from './duplicateBulkPayment';
import modifyBulkPaymentRecipient from './modifyBulkPaymentRecipient';
import calculateBulkPaymentOperation from './calculateBulkPaymentOperation';
import executeBulkPaymentOperation from './executeBulkPaymentOperation';
import bulkPaymentTemplate from './bulkPaymentTemplate';
import getHelpersList from './getHelpersList';
import addHelper from './addHelper';
import createOrganization from './createOrganization';
import getOrganizationList from './getOrganizationList';
import getSavingsKPI from './getSavingsKPI';
import deleteBulkPaymentOperation from './deleteBulkPaymentOperation';

export default (state = initialState, action = {}) => ({
  ...state,
  ...createBulkPayment(state, action),
  ...bulkPaymentList(state, action),
  ...getBulkPaymentDetails(state, action),
  ...addBulkPaymentRecipient(state, action),
  ...duplicateBulkPayment(state, action),
  ...modifyBulkPaymentRecipient(state, action),
  ...calculateBulkPaymentOperation(state, action),
  ...executeBulkPaymentOperation(state, action),
  ...bulkPaymentTemplate(state, action),
  ...getHelpersList(state, action),
  ...addHelper(state, action),
  ...createOrganization(state, action),
  ...getOrganizationList(state, action),
  ...getSavingsKPI(state, action),
  ...deleteBulkPaymentOperation(state, action),
});
