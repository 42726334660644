import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CELINE_MONEY } from 'constants/general';
import CashPooling from 'components/TransferMoney/cashPooling';
import wallet from '../CreditCard/creditCardDetails/creditCardDetails';
import { useHistory } from 'react-router-dom';
import confirmTransaction, {
  clearConfirmation,
} from 'redux/actions/moneyTransfer/confirmTransaction';
import transferFundsInternalData, {
  clearTransferFundsInternalStore,
} from 'redux/actions/moneyTransfer/transferFundsInternalData';

const CashPoolingContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [nextStep, setNextStep] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentWalletOption, setCurrentOption] = useState(null);
  const [secondWalletOption, setSecondWalletOption] = useState(null);
  const [option, setOption] = useState(0);
  const [getPin, setGetPin] = useState();
  const [amount, setAmount] = useState('');
  const [nextProcess, setNextProcess] = useState(true);
  const [confirmTransactionPin, setConfirmTransactionPin] = useState(
    {},
  );

  const {
    myWallets: { walletList },
    userData: { data: userData },
  } = useSelector(({ user }) => user);

  const {
    moneyTransfer: {
      confirmTransaction: {
        checking,
        confirmationError,
        confirmationData,
      },
      transferFundsInternal,
    },
  } = useSelector(state => state);

  const onHandleChangeAmount = e => setAmount(e.target.value);

  const onSubmitWalletTransactionConfirmation = () => {
    const data = {
      CountryCode: userData?.OperationCountry,
      Amount: amount,
      TargetCurrency: secondWalletOption?.CurrencyCode,
      TargetType: CELINE_MONEY,
      SourceWallet: currentWalletOption?.AccountNumber,
      TargetWallet: secondWalletOption?.AccountNumber,
    };
    confirmTransaction(data)(dispatch);
  };

  const onSubmitTransferFundsInternal = () => {
    const data = {
      PIN: getPin,
      Amount: amount,
      TargetWallet: secondWalletOption?.AccountNumber,
      SourceWallet: currentWalletOption?.AccountNumber,
      Reccurent: 'NO',
      Description: '',
      Reference: '',
      SendNow: 'YES',
    };

    transferFundsInternalData(data)(dispatch);
  };

  useEffect(() => {
    if (walletList) {
      const selectedWallet = walletList.find(
        wallet => wallet.Default === 'YES',
      );
      if (selectedWallet) {
        setCurrentOption(selectedWallet);
        setSecondWalletOption(walletList[1]);
      }
    }
  }, [walletList]);

  useEffect(() => {
    if (confirmationData) {
      setCurrentPage(4);
    }
  }, [confirmationData]);

  useEffect(() => {
    if (transferFundsInternal?.data) {
      setCurrentPage(0);
      history.push('/money-transfer');
      clearConfirmation()(dispatch);
      clearTransferFundsInternalStore()(dispatch);
      setAmount('');
    }
  }, [transferFundsInternal?.data]);

  const backBtn = () => {
    setNextStep(false);
    clearConfirmation()(dispatch);
    clearTransferFundsInternalStore()(dispatch);
    setAmount('');
  };

  return (
    <CashPooling
      checking={checking}
      walletList={walletList}
      currentPage={currentPage}
      setCurrentOption={setCurrentOption}
      currentOption={currentWalletOption}
      checking={checking}
      confirmationData={confirmationData}
      setAmount={setAmount}
      amount={amount}
      nextStep={nextStep}
      setNextStep={setNextStep}
      setGetPin={setGetPin}
      getPin={getPin}
      setCurrentPage={setCurrentPage}
      secondWalletOption={secondWalletOption}
      setSecondWalletOption={setSecondWalletOption}
      onHandleChangeAmount={onHandleChangeAmount}
      nextProcess={nextProcess}
      setNextProcess={setNextProcess}
      onSubmitWalletTransactionConfirmation={
        onSubmitWalletTransactionConfirmation
      }
      setConfirmTransactionPin={setConfirmTransactionPin}
      confirmTransactionPin={confirmTransactionPin}
      transferFundsInternalLoading={transferFundsInternal?.loading}
      onSubmitTransferFundsInternal={onSubmitTransferFundsInternal}
      backBtn={backBtn}
    />
  );
};
export default CashPoolingContainer;
