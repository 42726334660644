import {
  SEND_FUNDS_TO_GATEWAY_ERROR,
  SEND_FUNDS_TO_GATEWAY_START,
  SEND_FUNDS_TO_GATEWAY_SUCCESS,
  CLEAR_SEND_FUNDS_TO_GATEWAY_STORE,
} from 'constants/action-types/moneyTransfer';

export default (state, { type, payload }) => {
  switch (type) {
    case SEND_FUNDS_TO_GATEWAY_START:
      return {
        ...state,
        sendFundsToGateway: {
          ...state.sendFundsToGateway,
          loading: true,
          error: null,
        },
      };
    case SEND_FUNDS_TO_GATEWAY_ERROR:
      return {
        ...state,
        sendFundsToGateway: {
          ...state.sendFundsToGateway,
          error: payload,
          loading: false,
        },
      };
    case SEND_FUNDS_TO_GATEWAY_SUCCESS:
      return {
        ...state,
        sendFundsToGateway: {
          ...state.sendFundsToGateway,
          error: null,
          loading: false,
          data: payload,
        },
      };
    case CLEAR_SEND_FUNDS_TO_GATEWAY_STORE:
      return {
        ...state,
        sendFundsToGateway: {
          ...state.sendFundsToGateway,
          error: null,
          loading: false,
          data: null,
        },
      };
    default:
      return null;
  }
};
