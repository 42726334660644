import bulkPaymentDetails from './bulkPaymentDetails';
import createBulkPayment from './createBulkPayment';
import duplicateBulkPayment from './duplicateBulkPayment';
import organizations from './organizations';

export default [
  organizations,
  createBulkPayment,
  duplicateBulkPayment,
  bulkPaymentDetails,
];
