import { lazy } from 'react';

export default {
  exact: true,
  name: 'Organization',
  protected: true,
  path: '/organization',
  component: lazy(() =>
    import('containers/Organization/bulkPayment'),
  ),
};
