import * as ActionType from 'constants/action-types/organization';
import { toast } from 'react-toastify';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.CALCULATE_BULK_PAYMENT_OPERATION_START:
      return {
        ...state,
        calculateBulkPaymentOperation: {
          ...state.calculateBulkPaymentOperation,
          loading: true,
          error: null,
        },
      };
    case ActionType.CALCULATE_BULK_PAYMENT_OPERATION_SUCCESS:
      // toast.success(payload.Description);
      return {
        ...state,
        calculateBulkPaymentOperation: {
          ...state.calculateBulkPaymentOperation,
          data: payload,
          error: null,
          loading: payload?.OK === '200' ? true : false,
        },
      };
    case ActionType.CALCULATE_BULK_PAYMENT_OPERATION_ERROR:
      return {
        ...state,
        calculateBulkPaymentOperation: {
          ...state.calculateBulkPaymentOperation,
          data: payload,
          error: null,
          loading: false,
        },
      };

    default:
      return null;
  }
};
