import {
  VERIFY_GATEWAY_ACCOUNT_START,
  VERIFY_GATEWAY_ACCOUNT_SUCCESS,
  VERIFY_GATEWAY_ACCOUNT_ERROR,
  CLEAR_VERIFY_GATEWAY_ACCOUNT,
} from 'constants/action-types/payBills/getBillsCategories';

export default (state, { type, payload }) => {
  switch (type) {
    case VERIFY_GATEWAY_ACCOUNT_START:
      return {
        ...state,
        verifyGatewayAccounts: {
          ...state.verifyGatewayAccounts,
          loading: true,
        },
      };
    case VERIFY_GATEWAY_ACCOUNT_ERROR:
      return {
        ...state,
        verifyGatewayAccounts: {
          ...state.verifyGatewayAccounts,
          error: payload.error,
          loading: false,
        },
      };
    case VERIFY_GATEWAY_ACCOUNT_SUCCESS:
      return {
        ...state,
        verifyGatewayAccounts: {
          ...state.verifyGatewayAccounts,
          ...payload,
          success: true,
          loading: false,
        },
      };

    case CLEAR_VERIFY_GATEWAY_ACCOUNT:
      return {
        ...state,
        verifyGatewayAccounts: {
          ...state.verifyGatewayAccounts,
          success: true,
          loading: false,
          data: [],
          error: null,
        },
      };

    default:
      return null;
  }
};
