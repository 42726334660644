import React from 'react';
import './style.scss';

const { Modal, Button } = require('semantic-ui-react');

export const customReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return { open: false };
    case 'open':
      return {
        open: true,
        size: action.size,
      };
    case 'openCreateOperationModal':
      return {
        openCreateOperationModal: true,
        sizeCreateOperationModal: action.size,
      };

    case 'closeCreateOperationModal':
      return {
        openCreateOperationModal: false,
      };
    case 'openOperationSummaryModal':
      return {
        openOperationSummaryModal: true,
        sizeOperationSummaryModal: action.size,
      };
    case 'closeOperationSummaryModal':
      return {
        openOperationSummaryModal: false,
      };

    case 'openCalculatedOperationModal':
      return {
        openCalculatedOperationModal: true,
        sizeCalculatedOperationModal: action.size,
      };
    case 'closeCalculatedOperationModal':
      return {
        openCalculatedOperationModal: false,
      };
    case 'openExecutedOperationModal':
      return {
        openExecutedOperationModal: true,
        sizeExecutedOperationModal: action.size,
      };
    case 'closeExecutedOperationModal':
      return {
        openExecutedOperationModal: false,
      };

    case 'openUploadRecipientModal':
      return {
        openUploadRecipientModal: true,
        sizeUploadRecipientModal: action.size,
      };
    case 'closeUploadRecipientModal':
      return {
        openUploadRecipientModal: false,
      };

    case 'openDuplicateOperationModal':
      return {
        openDuplicateOperationModal: true,
        sizeDuplicateOperationModal: action.size,
      };
    case 'closeDuplicateOperationModal':
      return {
        openDuplicateOperationModal: false,
      };

    case 'openRecipientDetailsModal':
      return {
        openRecipientDetailsModal: true,
        sizeRecipientDetailModal: action.size,
      };
    case 'closeRecipientDetailModal':
      return {
        openRecipientDetailsModal: false,
      };

    default:
      throw new Error('Error action...');
  }
};

const CustomModal = ({ open, size, dispatch, title, children }) => {
  return (
    <Modal size={size} open={open} className="common-custom__modal">
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
};

export default CustomModal;
