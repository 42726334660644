export const MNO_PROVIDERS_TYPE = '30';
export const BANK_PROVIDERS_TYPE = '4';
export const MNO_GETAWAY_OPERATOR = '21';

export const ACTION_TYPE = {
  TRANSFER: '1',
};

export const TRANSFER_ACTION_TYPE = '1';
export const PAY_BILL_ACTION_TYPE = '2';
export const TOP_UP_ACTION_TYPE = '3';
