import {
    GET_ACTIVITY_SECTOR_LIST_START,
    GET_ACTIVITY_SECTOR_LIST_SUCCESS,
    GET_ACTIVITY_SECTOR_LIST_FAILURE,
    GET_ACTIVITY_SECTOR_LIST_END
} from "constants/action-types/userAccountManagement/activitySectorList";

  export default (state, { type, payload }) => {
    switch (type) {
      case GET_ACTIVITY_SECTOR_LIST_START:
        return {
          ...state,
          getActivitySectorList: {
            ...state.getActivitySectorList,
            loading: true,
            error: null,
          },
        };
      case  GET_ACTIVITY_SECTOR_LIST_SUCCESS:
        return {
          ...state,
          getActivitySectorList: {
            ...state.getActivitySectorList,
            data: payload,
            error: null,
            loading: false,
          },
        };
  
      case GET_ACTIVITY_SECTOR_LIST_FAILURE:
        return {
          ...state,
          getActivitySectorList: {
            ...state.getActivitySectorList,
            error: payload.error,
            loading: false,
          },
        };
      default:
        return null;
    }
  };
  