export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_IS_SENDING_CASH = 'SET_IS_SENDING_CASH';
export const SET_IS_SENDING_MONEY = 'SET_IS_SENDING_MONEY';
export const SET_MANAGE_CONTACTS = 'SET_MANAGE_CONTACTS';
export const SET_IS_SENDING_OTHERS = 'SET_IS_SENDING_OTHERS';
export const SET_IS_TOPING_UP = 'SET_IS_TOPING_UP';
export const SET_IS_SENDING_VOUCHER = 'SET_IS_SENDING_VOUCHER';
export const SET_CHAT_LIST_OPEN = 'SET_CHAT_LIST_OPEN';
export const SET_CHAT_LIST_CLOSED = 'SET_CHAT_LIST_CLOSED';
export const UPDATE_MONEY_TRANSFER_STEP =
  'UPDATE_MONEY_TRANSFER_STEP';
export const OPEN_PROFILE_DROP_DOWN = 'OPEN_PROFILE_DROP_DOWN';
export const CLOSE_PROFILE_DROP_DOWN = 'CLOSE_PROFILE_DROP_DOWN';
export const UPDATE_CREDIT_CARD_STEP = 'UPDATE_CREDIT_CARD_STEP';
export const CLEAR_CONTACT_ACTION = 'CLEAR_CONTACT_ACTION';
