import { lazy } from 'react';

export default {
  exact: true,
  name: 'create-bulk-payment',
  protected: true,
  path: '/create-bulk-payment',
  component: lazy(() =>
    import('containers/Organization/createBulkPayment'),
  ),
};
