import initialState from 'redux/initial-states/money-transfer';
import confirmTransaction from './confirmTransaction';
import moveFundsToWallet from './moveFundsToWallet';
import transferToOthers from './transferToOthers';
import sendFundsToGateway from './sendFundsToGateway';
import gatewayTransferConfirmation from './gatewayTransferConfirmation';
import transferFundInternal from './transferFundInternal';
import cashBackRequest from './cashBackRequest';
import addMoneyWithPayPal from './addMoneyWithPayPal';

export default (state = initialState, action = {}) => ({
  ...state,
  ...confirmTransaction(state, action),
  ...moveFundsToWallet(state, action),
  ...transferToOthers(state, action),
  ...sendFundsToGateway(state, action),
  ...gatewayTransferConfirmation(state, action),
  ...transferFundInternal(state, action),
  ...cashBackRequest(state, action),
  ...addMoneyWithPayPal(state, action),
});
