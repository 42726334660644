import React from 'react';
import { useSelector } from 'react-redux';

const useResponse = () => {
  const {
    user: {
      operation,
      walletUpdate,
      myWallets: { walletList },
      userData: { data: userData },
    },
    providersCountries: { providersList, providersCountries },
    payBills: {
      verifyGatewayAccounts,
      getBillsCategories: { billsCategories },
    },
    moneyTransfer: {
      gatewayTransactionConfirmation,
      sendFundsToGateway,
    },
    contacts: { locateWallet },
  } = useSelector(state => state);
  return {
    walletUpdate: walletUpdate,
    verifiedGatewayAccount: verifyGatewayAccounts,
    walletList: walletList,
    currentUser: userData,
    operations: operation?.data,
    providersData: providersList,
    providersCountries: providersCountries,
    billsCategories: billsCategories,
    requestFundsToGateway: sendFundsToGateway,
    transactionConfirmation: gatewayTransactionConfirmation,
    locateUser: locateWallet,
  };
};

export default useResponse;
