import React, { useEffect, useState } from 'react';
import { Button, Form, Image, Input } from 'semantic-ui-react';
import ContentBox from 'components/common/ContentBox';
import WalletDropDown from 'components/common/WalletDropDown';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import ShowCurrentBox from '../common/showCurrentBox';
import ConfirmationPage from './confirmTransaction';

const CashPooling = props => {
  const {
    currentPage,
    walletList,
    setCurrentOption,
    currentOption,
    setCurrentPage,
    nextStep,
    amount,
    loading,
    checking,
    option,
    setGetPin,
    getPin,
    secondWalletOption,
    setSecondWalletOption,
    setAmount,
    onHandleChangeAmount,
    nextProcess,
    setNextProcess,
    confirmationData,
    confirmTransactionPin,
    setConfirmTransactionPin,
    transferFundsInternalLoading,
    onSubmitTransferFundsInternal,
    onSubmitWalletTransactionConfirmation,
    backBtn,
    setNextStep,
  } = props;

  const [firstWalletSelected, setFirstWalletSelected] = useState();
  const [secondWalletSelected, setSecondWalletSelected] = useState();
  const [alertMsg, setAlertMsg] = useState(false);

  useEffect(() => {
    if (
      currentOption?.AccountNumber !==
      secondWalletOption?.AccountNumber
    ) {
      setFirstWalletSelected(currentOption);
      setSecondWalletSelected(secondWalletOption);
      setAlertMsg(false);
      setNextProcess(false);
    } else {
      setAlertMsg(true);
      setNextProcess(true);
    }
  }, [currentOption, secondWalletOption]);

  return (
    <>
      <ContentBox title="Cash pooling" backBtn={backBtn}>
        <ShowCurrentBox steps={nextStep} />
        <h3 className="page-title">
          {global.translate('Cash pooling')}
        </h3>
        {[0].includes(currentPage) ? (
          <>
            <div>
              {walletList?.length !== 0 ? (
                <>
                  <div className="cash-out-title">
                    {global.translate('Source wallet')}
                  </div>
                  <div className="wallet-select">
                    <WalletDropDown
                      walletList={walletList}
                      setCurrentOption={setCurrentOption}
                      currentOption={firstWalletSelected}
                    />
                  </div>
                </>
              ) : (
                <div
                  className="load-data"
                  style={{
                    paddingTop: '8px',
                    paddingBottom: '12px',
                  }}
                >
                  <Image
                    src={LoadWalletImg}
                    className="animate-placeholder"
                  />
                </div>
              )}
            </div>
            <div>
              {walletList?.length !== 0 ? (
                <>
                  <div className="cash-out-title">
                    {global.translate('Target wallet')}
                  </div>
                  <div className="wallet-select">
                    <WalletDropDown
                      walletList={walletList}
                      setCurrentOption={setSecondWalletOption}
                      currentOption={secondWalletOption}
                    />
                  </div>
                </>
              ) : (
                <div
                  className="load-data"
                  style={{
                    paddingTop: '8px',
                    paddingBottom: '12px',
                  }}
                >
                  <Image
                    src={LoadWalletImg}
                    className="animate-placeholder"
                  />
                </div>
              )}
            </div>
            {alertMsg !== false ? (
              <>
                {' '}
                <div
                  className="card-box__items-field"
                  style={{ padding: '0px', border: 'none' }}
                >
                  <div className="warning-box">
                    <span>
                      {global.translate(
                        "You can't perform currency exchange with a single wallet. Please select another wallet.",
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : null}

            <div className="card-box__field">
              <span className="card-title">
                {global.translate('Amount')}
              </span>
              <div className="input-field">
                <Input
                  label={{
                    basic: true,
                    content: currentOption?.CurrencyCode,
                  }}
                  labelPosition="right"
                  value={amount}
                  onChange={onHandleChangeAmount}
                  name="amount"
                  placeholder={global.translate('0.0')}
                />
              </div>
            </div>
            <div className="bottom-transition__box">
              <div></div>
              <Button
                onClick={() => {
                  onSubmitWalletTransactionConfirmation();
                  setNextStep(true);
                }}
                loading={checking}
                disabled={amount === '' ? true : false}
                style={{ marginLeft: '10px' }}
              >
                {global.translate('Proceed')}
              </Button>
            </div>
          </>
        ) : null}
        {currentPage === 4 ? (
          <ConfirmationPage
            transactionConfirmationData={confirmationData}
            confirmTransactionPin={confirmTransactionPin}
            setConfirmTransactionPin={setConfirmTransactionPin}
            setCurrentPage={setCurrentPage}
            onSubmitTransferFundsInternal={
              onSubmitTransferFundsInternal
            }
            setGetPin={setGetPin}
            loading={transferFundsInternalLoading}
            currentPage={currentPage}
          />
        ) : null}
      </ContentBox>
    </>
  );
};

export default CashPooling;
