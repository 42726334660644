export const UPDATE_SECURITY_QUESTIONS_START =
  'UPDATE_SECURITY_QUESTIONS_START';
export const UPDATE_SECURITY_QUESTIONS_SUCCESS =
  'UPDATE_SECURITY_QUESTIONS_SUCCESS';
export const UPDATE_SECURITY_QUESTIONS_FAILURE =
  'UPDATE_SECURITY_QUESTIONS_FAILURE';
export const UPDATE_SECURITY_QUESTIONS_END =
  'UPDATE_SECURITY_QUESTIONS_END';
export const CLEAR_UPDATE_SECURITY_QUESTIONS =
  'CLEAR_UPDATE_SECURITY_QUESTIONS';
