import {
  RECALL_TRANSACTION_RESET,
  RECALL_TRANSACTION_START,
  RECALL_TRANSACTION_SUCCESS,
  RECALL_TRANSACTION_ERROR,
} from 'constants/action-types/transactions/recallDisputeTransaction';

export default (state, { type, payload }) => {
  switch (type) {
    case RECALL_TRANSACTION_RESET:
      return {
        ...state,
        recallTX: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case RECALL_TRANSACTION_START:
      return {
        ...state,
        recallTX: {
          ...state.recallTX,
          loading: true,
          error: null,
        },
      };
    case RECALL_TRANSACTION_ERROR:
      return {
        ...state,
        recallTX: {
          ...state.recallTX,
          error: payload,
          loading: false,
        },
      };
    case RECALL_TRANSACTION_SUCCESS:
      return {
        ...state,
        recallTX: {
          ...state.recallTX,
          error: null,
          loading: false,
          data: payload,
        },
      };

    default:
      return null;
  }
};
