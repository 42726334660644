import WithdrawCash from 'components/TransferMoney/withdrawCash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CELINE_MONEY, BANK_TRANSFER } from 'constants/general';
import moveFunds from 'redux/actions/moneyTransfer/moveFunds';
import dispatch from '../../redux/actions/peerServices/closeCreateModal';
import confirmTransaction from 'redux/actions/moneyTransfer/confirmTransaction';
import { toast } from 'react-toastify';

const WithdrawCashContainer = () => {
  const dispatch = useDispatch();
  const [nextStep, setNextStep] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentWalletOption, setCurrentOption] = useState(null);
  const [targetWallet, setTargetWallet] = useState();
  const [option, setOption] = useState(0);
  const [getPin, setGetPin] = useState();
  const [confirmTransactionPin, setConfirmTransactionPin] = useState(
    {},
  );

  const [form, setForm] = useState({
    amount: '',
    accountName: '',
    accountNumber: '',
    phoneNumber: '',
    countryCode: '',
    firstName: '',
    lastName: '',
  });

  const onHandleChangeAmount = event => {
    const { name, value } = event.target;
    const list = { ...form };
    list[name] = value;
    setForm(list);
  };

  const {
    myWallets: { walletList },
    userData: { data: userData },
  } = useSelector(({ user }) => user);

  const {
    moneyTransfer: {
      confirmTransaction: {
        checking,
        confirmationError,
        confirmationData,
      },
      moveFundsTo2UWallet,
    },
  } = useSelector(state => state);

  useEffect(() => {
    if (walletList) {
      const selectedWallet = walletList.find(
        wallet => wallet.Default === 'YES',
      );

      if (selectedWallet) {
        setCurrentOption(selectedWallet);
      }
    }
  }, [walletList]);

  useEffect(() => {
    if (moveFundsTo2UWallet?.data) {
      setCurrentPage(0);
      toast.success(`${moveFundsTo2UWallet?.data[0]?.Description}`);
    }
  }, [moveFundsTo2UWallet?.data]);

  useEffect(() => {
    if (confirmationData) {
      setCurrentPage(4);
    }
  }, [confirmationData]);

  const onSubmit = () => {
    setNextStep(true);
    setCurrentPage(option);
  };

  const onSubmitWalletTransactionConfirmation = () => {
    const data = {
      CountryCode: userData?.OperationCountry,
      Amount: form.amount,
      TargetCurrency: currentWalletOption?.CurrencyCode,
      TargetType: currentPage === 1 ? CELINE_MONEY : BANK_TRANSFER,
      SourceWallet: currentWalletOption?.AccountNumber,
    };
    confirmTransaction(data)(dispatch);
  };

  const onMoveFounds = () => {
    const data = {
      PIN: getPin,
      CountryCode: userData?.OperationCountry,
      Amount: form?.amount,
      SourceWallet: currentWalletOption?.AccountNumber,
      DateFrom: '',
      DateTo: '',
      Reccurent: 'NO',
      SendNow: 'YES',
      Reference: 'Withdraw money',
      Description: '',
      FirstName: form?.firstName,
      LastName: form?.lastName,
      TargetPhoneNumber: form?.phoneNumber,
      DestCountryCode: userData?.OperationCountry,
    };

    moveFunds(data, '/SendCash', 'send-cash')(dispatch)();
  };

  return (
    <>
      <WithdrawCash
        walletList={walletList}
        currentOption={currentWalletOption}
        setCurrentOption={setCurrentOption}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        targetWallet={targetWallet}
        setTargetWallet={setTargetWallet}
        // amount={amount}
        userData={userData}
        form={form}
        setForm={setForm}
        option={option}
        setOption={setOption}
        onHandleChangeAmount={onHandleChangeAmount}
        checking={checking}
        setGetPin={setGetPin}
        getPin={getPin}
        confirmationData={confirmationData}
        setConfirmTransactionPin={setConfirmTransactionPin}
        confirmTransactionPin={confirmTransactionPin}
        onSubmitWalletTransactionConfirmation={
          onSubmitWalletTransactionConfirmation
        }
        onMoveFounds={onMoveFounds}
        moveFundsTo2UWalletLoading={moveFundsTo2UWallet?.loading}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default WithdrawCashContainer;
