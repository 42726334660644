import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Input, Button, Image } from 'semantic-ui-react';

const SelfWithdraw = props => {
  const {
    selectedAccountNumber,
    onHandleGetAccountNumber,
    locateWallet,
    onSearchAccountNumber,
    walletFound,
    onHandleChangeAmount,
    userData,
    form,
    loading,
    currentOption,
    onSubmitWalletTransactionConfirmation,
    setCurrentPage,
    setForm,
  } = props;
  const [enableNextProcess, setEnableNextProcess] = useState(true);

  useEffect(() => {
    if (form?.amount) {
      setEnableNextProcess(false);
    }
  }, [form?.amount]);

  return (
    <>
      <div className="card-box__field">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div className="card-box__field" style={{ width: '49%' }}>
            <span className="card-title">
              {global.translate('First name')}
            </span>
            <div className="input-field">
              <Input
                disabled={true}
                value={userData?.FirstName}
                onChange={onHandleChangeAmount}
                name="text"
                placeholder={global.translate('Your first name')}
              />
            </div>
          </div>
          <div className="card-box__field" style={{ width: '49%' }}>
            <span className="card-title">
              {global.translate('Last name')}
            </span>
            <div className="input-field">
              <Input
                disabled={true}
                value={userData?.LastName}
                onChange={onHandleChangeAmount}
                name="text"
                placeholder={global.translate('Your last name')}
              />
            </div>
          </div>
        </div>
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Phone number')}
          </span>
          <div
            className="operator-input"
            style={{ marginTop: '10px' }}
          >
            <PhoneInput
              disabled={true}
              country={userData?.Country?.toLowerCase()}
              enableSearch
              value={userData?.MainPhone}
              currentOption={form.phoneNumber}
              name="text"
              containerClass="phone-number-container"
              inputClass="phone-number-input"
              onChange={(phone, data) => {
                setForm({
                  phoneNumber: phone,
                  countryCode: data.countryCode,
                });
              }}
              placeholder={global.translate('Phone Number')}
            />
          </div>
        </div>
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Amount')}
          </span>
          <div className="input-field">
            <Input
              label={{
                basic: true,
                content: currentOption?.CurrencyCode,
              }}
              labelPosition="right"
              value={form?.amount}
              onChange={onHandleChangeAmount}
              name="amount"
              placeholder={global.translate('0.0')}
            />
          </div>
        </div>
      </div>

      <div
        className="bottom-transition__box"
        style={{ marginTop: '-15px' }}
      >
        <Button id="btn-back" onClick={() => setCurrentPage(0)}>
          {global.translate('Back')}
        </Button>

        <Button
          onClick={() => onSubmitWalletTransactionConfirmation()}
          loading={loading}
          disabled={enableNextProcess}
          style={{ marginLeft: '10px' }}
        >
          {global.translate('Proceed')}
        </Button>
      </div>
    </>
  );
};

export default SelfWithdraw;
