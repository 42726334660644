export const REQUEST_RETURN_SAVINGS_DURATION_ARRAY_START =
  'REQUEST_RETURN_SAVINGS_DURATION_ARRAY_START';
export const REQUEST_RETURN_SAVINGS_DURATION_ARRAY_SUCCESS =
  'REQUEST_RETURN_SAVINGS_DURATION_ARRAY_SUCCESS';
export const REQUEST_RETURN_SAVINGS_DURATION_ARRAY_ERROR =
  'REQUEST_RETURN_SAVINGS_DURATION_ARRAY_ERROR';
export const REQUEST_RETURN_SAVINGS_DURATION_ARRAY_END =
  'REQUEST_RETURN_SAVINGS_DURATION_ARRAY_END';
export const CLEAR_REQUEST_RETURN_SAVINGS_DURATION_ARRAY =
  'CLEAR_REQUEST_RETURN_SAVINGS_DURATION_ARRAY';

export const GET_SAVINGS_LIST_START = 'GET_SAVINGS_LIST_START';
export const GET_SAVINGS_LIST_SUCCESS = 'GET_SAVINGS_LIST_SUCCESS';
export const GET_SAVINGS_LIST_ERROR = 'GET_SAVINGS_LIST_ERROR';
export const GET_SAVINGS_LIST_END = 'GET_SAVINGS_LIST_END';
export const CLEAR_GET_SAVINGS_LIST = 'CLEAR_GET_SAVINGS_LIST';

export const CREATE_SAVINGS_TRANSFER_CONFIRMATION_START =
  'CREATE_SAVINGS_TRANSFER_CONFIRMATION_START';
export const CREATE_SAVINGS_TRANSFER_CONFIRMATION_SUCCESS =
  'CREATE_SAVINGS_TRANSFER_CONFIRMATION_SUCCESS';
export const CREATE_SAVINGS_TRANSFER_CONFIRMATION_ERROR =
  'CREATE_SAVINGS_TRANSFER_CONFIRMATION_ERROR';
export const CREATE_SAVINGS_TRANSFER_CONFIRMATION_END =
  'CREATE_SAVINGS_TRANSFER_CONFIRMATION_END';
export const CLEAR_CREATE_SAVINGS_TRANSFER_CONFIRMATION =
  'CLEAR_CREATE_SAVINGS_TRANSFER_CONFIRMATION';

export const EXECUTE_SAVINGS_PLAN_START =
  'EXECUTE_SAVINGS_PLAN_START';
export const EXECUTE_SAVINGS_PLAN_SUCCESS =
  'EXECUTE_SAVINGS_PLAN_SUCCESS';
export const EXECUTE_SAVINGS_PLAN_ERROR =
  'EXECUTE_SAVINGS_PLAN_ERROR';
export const EXECUTE_SAVINGS_PLAN_END = 'EXECUTE_SAVINGS_PLAN_END';
export const CLEAR_EXECUTE_SAVINGS_PLAN =
  'CLEAR_EXECUTE_SAVINGS_PLAN';

export const SAVINGS_CANCEL_CONFIRMATION_START =
  'SAVINGS_CANCEL_CONFIRMATION_START';
export const SAVINGS_CANCEL_CONFIRMATION_SUCCESS =
  'SAVINGS_CANCEL_CONFIRMATION_SUCCESS';
export const SAVINGS_CANCEL_CONFIRMATION_ERROR =
  'SAVINGS_CANCEL_CONFIRMATION_ERROR';
export const SAVINGS_CANCEL_CONFIRMATION_END =
  'SAVINGS_CANCEL_CONFIRMATION_END';
export const CLEAR_SAVINGS_CANCEL_CONFIRMATION =
  'CLEAR_SAVINGS_CANCEL_CONFIRMATION';

export const CANCEL_SAVINGS_PLAN_START = 'CANCEL_SAVINGS_PLAN_START';
export const CANCEL_SAVINGS_PLAN_SUCCESS =
  'CANCEL_SAVINGS_PLAN_SUCCESS';
export const CANCEL_SAVINGS_PLAN_ERROR = 'CANCEL_SAVINGS_PLAN_ERROR';
export const CANCEL_SAVINGS_PLAN_END = 'CANCEL_SAVINGS_PLAN_END';
export const CLEAR_CANCEL_SAVINGS_PLAN = 'CLEAR_CANCEL_SAVINGS_PLAN';
