import * as ActionType from 'constants/action-types/gimac';

export default (state, { type, payload }) => {
    switch (type) {
        case ActionType.GIMAC_CARDLESS_ATM_START:
            return {
                ...state,
                cardlessAtm: {
                    ...state.cardlessAtm,
                    loading: true,
                    error: null,
                },
            };
        case ActionType.GIMAC_CARDLESS_ATM_SUCCESS:
            return {
                ...state,
                cardlessAtm: {
                    ...state.cardlessAtm,
                    data: payload,
                    error: null,
                    loading: false,
                },
            };
        case ActionType.GIMAC_CARDLESS_ATM_FAILURE:
            return {
                ...state,
                cardlessAtm: {
                    ...state.cardlessAtm,
                    data: payload,
                    error: null,
                    loading: false,
                },
            };
        case ActionType.GIMAC_CARDLESS_ATM_CLEAR:
            return {
                ...state,
                cardlessAtm: {
                    ...state.cardlessAtm,
                    data: null,
                    error: null,
                    loading: false,
                },
                transferConfirmation: {
                    ...state.transferConfirmation,
                    data: null,
                    error: null,
                    loading: false,
                },
            };
        default:
            return null;
    }
};