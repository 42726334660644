export const GET_SUPPORTED_LANGUAGE_START =
  'GET_SUPPORTED_LANGUAGE_START';
export const GET_SUPPORTED_LANGUAGE_SUCCESS =
  'GET_SUPPORTED_LANGUAGE_SUCCESS';
export const GET_SUPPORTED_LANGUAGE_FAILURE =
  'GET_SUPPORTED_LANGUAGE_FAILURE';
export const GET_SUPPORTED_LANGUAGE_END =
  'GET_SUPPORTED_LANGUAGE_END';

export const GET_LANGUAGE_START = 'GET_LANGUAGE_START';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_FAILURE = 'GET_LANGUAGE_FAILURE';
export const GET_LANGUAGE_END = 'GET_LANGUAGE_END';

export const CHANGE_LANGUAGE_START = 'CHANGE_LANGUAGE_START';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_ERROR = 'CHANGE_LANGUAGE_ERROR';
export const CHANGE_LANGUAGE_END = 'CHANGE_LANGUAGE_END';
