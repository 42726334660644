export const UPDATE_USER_PHONE_LIST_START =
  'UPDATE_USER_PHONE_LIST_START';
export const UPDATE_USER_PHONE_LIST_SUCCESS =
  'UPDATE_USER_PHONE_LIST_SUCCESS';
export const UPDATE_USER_PHONE_LIST_FAILURE =
  'UPDATE_USER_PHONE_LIST_FAILURE';
export const UPDATE_USER_PHONE_LIST_END =
  'UPDATE_USER_PHONE_LIST_END';
export const CLEAR_UPDATE_USER_PHONE_LIST =
  'CLEAR_UPDATE_USER_PHONE_LIST';
export const UPDATE_USER_PHONE_LIST = 'UPDATE_USER_PHONE_LIST';

export const DELETE_PHONE_START = 'DELETE_PHONE_START';
export const DELETE_PHONE_SUCCESS = 'DELETE_PHONE_SUCCESS';
export const DELETE_PHONE_FAILURE = 'DELETE_PHONE_FAILURE';
export const CLEAR_DELETE_PHONE = 'CLEAR_DELETE_PHONE';
