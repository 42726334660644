export const RESET_PREQUALIFICATION_START =
  'RESET_PREQUALIFICATION_START';
export const RESET_PREQUALIFICATION_SUCCESS =
  'RESET_PREQUALIFICATION_SUCCESS';
export const RESET_PREQUALIFICATION_ERROR =
  'RESET_PREQUALIFICATION_ERROR ';
export const RESET_PREQUALIFICATION_END =
  'RESET_PREQUALIFICATION_END';
export const RESET_PREQUALIFICATION_CLEAR =
  'RESET_PREQUALIFICATION_CLEAR';
