export const UPDATE_USER_EMAIL_LIST_START =
  'UPDATE_USER_EMAIL_LIST_START';
export const UPDATE_USER_EMAIL_LIST_SUCCESS =
  'UPDATE_USER_EMAIL_LIST_SUCCESS';
export const UPDATE_USER_EMAIL_LIST_FAILURE =
  'UPDATE_USER_EMAIL_LIST_FAILURE';
export const UPDATE_USER_EMAIL_LIST_END =
  'UPDATE_USER_EMAIL_LIST_END';
export const CLEAR_UPDATE_USER_EMAIL_LIST =
  'CLEAR_UPDATE_USER_EMAIL_LIST';
export const UPDATE_USER_EMAIL_LIST = 'UPDATE_USER_EMAIL_LIST';
