export const GET_COUNTRY_CURRENCIES_START =
  'GET_COUNTRY_CURRENCIES_START';
export const GET_COUNTRY_CURRENCIES_SUCCESS =
  'GET_COUNTRY_CURRENCIES_SUCCESS';
export const GET_COUNTRY_CURRENCIES_ERROR =
  'GET_COUNTRY_CURRENCIES_ERROR';
export const GET_COUNTRY_CURRENCIES_END =
  'GET_COUNTRY_CURRENCIES_END';
export const RESTORE_COUNTRY_CURRENCIES =
  'RESTORE_COUNTRY_CURRENCIES';
