export const DISPUTE_TRANSACTION_RESET = 'DISPUTE_TRANSACTION_RESET';
export const DISPUTE_TRANSACTION_START = 'DISPUTE_TRANSACTION_START';
export const DISPUTE_TRANSACTION_ERROR = 'DISPUTE_TRANSACTION_ERROR';
export const DISPUTE_TRANSACTION_SUCCESS =
  'DISPUTE_TRANSACTION_SUCCESS';

export const RECALL_TRANSACTION_RESET = 'RECALL_TRANSACTION_RESET';
export const RECALL_TRANSACTION_START = 'RECALL_TRANSACTION_START';
export const RECALL_TRANSACTION_ERROR = 'RECALL_TRANSACTION_ERROR';
export const RECALL_TRANSACTION_SUCCESS =
  'RECALL_TRANSACTION_SUCCESS';
