import React from 'react';
import './index.scss';
import { Modal } from 'semantic-ui-react';

const CustomModal = ({ open, size, dispatch, title, children }) => {
  return (
    <Modal size={size} open={open} className="common-custom__modal">
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
};

export default CustomModal;
