import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import getSavingsList from 'redux/actions/savings/getSavingsList';
import saveSavingsTransferConfirmation, {
  clearSaveSavingsTransferConfirmation,
} from 'redux/actions/savings/saveSavingsTransferConfirmation';
import saveExecuteSavingsPlan, {
  clearExecuteSavingsPlan,
} from 'redux/actions/savings/saveExecuteSavingsPlan';
import getReturnSavingsDurationArray from 'redux/actions/savings/getReturnSavingsDurationArray';
import requestSavingsCancelConfirmation from 'redux/actions/savings/requestSavingsCancelConfirmation';
import moment from 'moment';
import { success } from 'assets/images/success.svg';
import { clearSavingsCancelConfirmation } from 'redux/actions/savings/requestSavingsCancelConfirmation';
import requestSavingsCancelPlan, {
  clearSavingsCancelPlan,
} from 'redux/actions/savings/requestSavingsCancelPlan';
import Savings from 'components/Savings';

const SavingsContainer = () => {
  const dispatch = useDispatch();
  const [PIN, setPIN] = useState();
  const [openPinModal, setOpenPinModal] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState();
  const [currentWallet, setCurrentWallet] = useState(null);
  const [openSavingsTerms, setOpenSavingsTerms] = useState(false);
  const [openSavingsDetails, setOpenSavingsDetails] = useState(false);
  const [openClaimPL, setOpenClaimPL] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [activePage, setActivePage] = useState('1');

  const [
    openCancelSavingPinModal,
    setOpenCancelSavingsPinModal,
  ] = useState(false);
  const [cancelSavingsPIN, setCancelSavingPIN] = useState();

  const [payload, setPayload] = useState({
    UserPID: '',
    DateFrom: moment()
      .clone()
      .subtract(1, 'years')
      .format('YYYY-MM-DD'),
    DateTo: moment().format('YYYY-MM-DD'),
    PageNumber: activePage,
    RecordPerPage: '15',
  });
  const [
    openSavingsTransferConfirmation,
    setOpenSavingsTransferConfirmation,
  ] = useState(false);
  const [openCreateSavingsPlan, setOpenCreateSavingsPlan] = useState(
    false,
  );
  const [form, setForm] = useState({
    amount: '',
  });
  const cardLabels = [
    {
      title: 'Maturity date',
      status: '0',
    },
    {
      title: 'Savings collected',
      status: '1',
    },
    {
      title: 'Savings collected',
      status: '3',
    },
  ];

  const {
    savings: {
      returnSavingsDurationArray,
      savingsList,
      savingsTransferConfirmation,
      executeSavingsPlan,
      savingsCancelConfirmation,
      savingsCancelPlan,
    },
    user: {
      userData: { data: userData },
      myWallets,
    },
    savings,
  } = useSelector(state => ({
    savings: state.nSavings,
    user: state.user,
  }));

  const savingsData = {
    ...(savingsList?.data && savingsList?.data[0]),
    loading: savingsList?.loading,
    error: savingsList?.error,
  };

  const operator = {
    ...(returnSavingsDurationArray?.operatorData &&
      returnSavingsDurationArray?.operatorData[0]),
    loading: returnSavingsDurationArray?.loadingOperator,
    error: returnSavingsDurationArray?.operatorError,
  };

  const onOpenSavingsTerms = () => {
    setOpenSavingsTerms(true);
  };

  const onCloseSavingsTerms = () => {
    setOpenSavingsTerms(false);
  };

  const onCloseCreateSavingsPlan = () => {
    setOpenCreateSavingsPlan(false);
    setForm({ amount: '' });
  };

  const onAgreeTermsAndCondition = () => {
    setOpenCreateSavingsPlan(true);
    setOpenSavingsTerms(false);
  };

  const onHandleSelectDuration = (e, data) => {
    setSelectedDuration(data.value);
  };

  const onChangePage = (e, pageInfo) => {
    setActivePage(`${pageInfo?.activePage}`);
  };

  const onCreateSaving = () => {
    saveSavingsTransferConfirmation({
      Amount: form.amount,
      Duration: selectedDuration || '',
      SourceWallet: currentWallet?.AccountNumber,
    })(dispatch);
  };

  const onSavingsCancelConfirmation = () => {
    requestSavingsCancelConfirmation({
      TransactionID: selectedItem?.TransactionID,
    })(dispatch);
  };

  const onExecuteSavingsPlan = () => {
    saveExecuteSavingsPlan({
      Amount: form.amount,
      Duration: selectedDuration || '',
      SourceWallet: currentWallet?.AccountNumber,
      PIN: PIN,
    })(dispatch);
  };

  const cancelSavingsCreation = () => {
    setOpenSavingsTransferConfirmation(false);
    clearSaveSavingsTransferConfirmation()(dispatch);
    clearSavingsCancelConfirmation()(dispatch);
    clearSavingsCancelPlan()(dispatch);
  };

  const onHandleChangeInput = event => {
    const { name, value } = event.target;
    const formData = { ...form };
    formData[name] = value;
    setForm(formData);
  };

  const onCloseClaimPrematurePayout = () => {
    setOpenClaimPL(false);
  };

  const onOpenClaimPrematurePayout = () => {
    setOpenClaimPL(true);
  };

  const onHandleSavingsCancelPlan = () => {
    requestSavingsCancelPlan({
      PIN: cancelSavingsPIN,
      TransactionID: selectedItem?.TransactionID,
    })(dispatch);
  };

  useEffect(() => {
    if (operator.error) {
      toast.error(operator?.Description || '');
    }

    if (savingsData?.error) {
      toast.error(savingsList?.error?.Description);
    }
  }, [operator, savingsData]);

  useEffect(() => {
    if (myWallets?.walletList.length) {
      setCurrentWallet(() =>
        myWallets?.walletList.find(
          wallet => wallet.Default === 'YES',
        ),
      );
    }
  }, [myWallets?.walletList.length]);

  useEffect(() => {
    if (savingsTransferConfirmation?.error) {
      toast.error(savingsTransferConfirmation?.error?.Description);
      clearSaveSavingsTransferConfirmation()(dispatch);
    }

    if (savingsTransferConfirmation?.data) {
      setOpenSavingsTransferConfirmation(true);
      setOpenCreateSavingsPlan(false);
    }

    if (savingsCancelConfirmation?.data) {
      setOpenClaimPL(false);
      setOpenSavingsTransferConfirmation(true);
      toast.success(savingsCancelConfirmation?.data?.Description);
    }

    if (savingsCancelPlan?.data) {
      setOpenClaimPL(false);
      setOpenCancelSavingsPinModal(false);
      clearSavingsCancelPlan()(dispatch);
      clearSaveSavingsTransferConfirmation()(dispatch);
      getSavingsList(payload)(dispatch);
      toast.success(savingsCancelPlan?.data?.Description);
    }

    if (executeSavingsPlan?.data) {
      const savingsPlanData =
        executeSavingsPlan?.data?.executeSavingsPlan;
      const data = Array.isArray(savingsPlanData)
        ? savingsPlanData[0]
        : savingsPlanData || {};
      setOpenPinModal(false);
      clearExecuteSavingsPlan()(dispatch);
      getSavingsList(payload)(dispatch);
      setForm({ amount: '' });
      toast.success(executeSavingsPlan?.data?.Description);
    }
  }, [
    savingsTransferConfirmation,
    executeSavingsPlan?.data,
    savingsCancelConfirmation?.data,
    savingsCancelPlan,
  ]);

  useEffect(() => {
    setPayload(prev => ({
      ...prev,
      PageNumber: activePage,
      UserPID: userData?.PID,
    }));
  }, [userData, activePage]);

  useEffect(() => {
    getSavingsList(payload)(dispatch);
    getReturnSavingsDurationArray()(dispatch);
  }, [userData, payload]);

  return (
    <Savings
      operator={operator}
      savings={savingsData}
      cardLabels={cardLabels}
      openSavingsTerms={openSavingsTerms}
      onOpenSavingsTerms={onOpenSavingsTerms}
      onCloseSavingsTerms={onCloseSavingsTerms}
      openCreateSavingsPlan={openCreateSavingsPlan}
      onCloseCreateSavingsPlan={onCloseCreateSavingsPlan}
      onAgreeTermsAndCondition={onAgreeTermsAndCondition}
      currentWallet={currentWallet}
      setCurrentWallet={setCurrentWallet}
      walletList={myWallets?.walletList}
      onHandleSelectDuration={onHandleSelectDuration}
      selectedDuration={selectedDuration}
      form={form}
      onCreateSaving={onCreateSaving}
      onHandleChangeInput={onHandleChangeInput}
      savingsTransferConfirmation={savingsTransferConfirmation}
      transferConfirmation={savingsTransferConfirmation?.data}
      openSavingsTransferConfirmation={
        openSavingsTransferConfirmation
      }
      cancelSavingsCreation={cancelSavingsCreation}
      openPinModal={openPinModal}
      setOpenPinModal={setOpenPinModal}
      PIN={PIN}
      setPIN={setPIN}
      executeSavingsPlan={executeSavingsPlan}
      onExecuteSavingsPlan={onExecuteSavingsPlan}
      openClaimPL={openClaimPL}
      onOpenClaimPrematurePayout={onOpenClaimPrematurePayout}
      onCloseClaimPrematurePayout={onCloseClaimPrematurePayout}
      savingsCancelConfirmation={savingsCancelConfirmation}
      onSavingsCancelConfirmation={onSavingsCancelConfirmation}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      savingsCancelPlan={savingsCancelPlan}
      onHandleSavingsCancelPlan={onHandleSavingsCancelPlan}
      openSavingsDetails={openSavingsDetails}
      setOpenSavingsDetails={setOpenSavingsDetails}
      openCancelSavingPinModal={openCancelSavingPinModal}
      setOpenCancelSavingsPinModal={setOpenCancelSavingsPinModal}
      setCancelSavingPIN={setCancelSavingPIN}
      cancelSavingsPIN={cancelSavingsPIN}
      activePage={activePage}
      onChangePage={onChangePage}
    />
  );
};

export default SavingsContainer;
