/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';

import { OPERATION_FAILED } from 'constants/events/operations';
import socketIOClient from 'services/socketIO';
import getMyWalletsAction from 'redux/actions/users/getMyWallets';
import getWalletTransactions from 'redux/actions/transactions/getWalletTransactions';
import notifAction from 'redux/actions/users/notifications';
import isAppDisplayedInWebView from 'helpers/isAppDisplayedInWebView';
import operations from 'redux/actions/users/operations';

export default () => {
  const dispatch = useDispatch();

  const { userData: { data } = {} } = useSelector(({ user }) => user);

  const fromDate = moment()
    .subtract(12, 'months')
    .format('YYYY-MM-DD');
  const toDate = moment().format('YYYY-MM-DD');

  const newDispatch = ({ type, payload }) => {
    delete payload.onStart;
    delete payload.onFailure;
    delete payload.onEnd;
    const action = {
      type,
      payload,
    };
    return dispatch(action);
  };

  useEffect(() => {
    if (data && data.PID) {
      socketIOClient.off(OPERATION_FAILED);
      socketIOClient.on(OPERATION_FAILED, notification => {
        getMyWalletsAction()(newDispatch);
        getWalletTransactions({
          WalletNumber: notification.data.TargetWallet,
          DateFrom: fromDate,
          DateTo: toDate,
          PageNumber: '1',
          RecordPerPage: '7',
          Proxy: 'No',
        })(newDispatch);

        notifAction({ PID: data.PID })(newDispatch);

        const { message } = notification || {};

        operations(
          dispatch({
            type: OPERATION_FAILED,
            payload: notification,
          }),
        );

        if (
          !isAppDisplayedInWebView() &&
          Object.keys(notification.data).length !== 0
        ) {
          Swal.fire({
            title: global.translate('Failed'),
            text: global.translate(message),
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: true,
          });
        }
      });
    }
    return () => {
      socketIOClient.off(OPERATION_FAILED);
    };
  }, [data && data.PID]);
};
