import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import './index.scss';

const NoResultFound = ({ title, subTitle, Icon, open }) => {
  return (
    <div className="container__column-list">
      <div className="container__column-items">
        <div className="container__row"></div>
        <div className="no-result-found-container">
          <Image src={Icon} className="image-size" />
          <span className="container-title">
            {global.translate(title)}
          </span>
          <span className="container-sub-title">
            {global.translate(subTitle)}
          </span>

          <div className="btn-container-box">
            <Button className="simple-btn" onClick={open}>
              <span>{global.translate('Start now')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResultFound;
