import * as ActionType from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.GET_BULK_PAYMENT_DETAILS_START:
      return {
        ...state,
        bulkPaymentDetails: {
          ...state.bulkPaymentDetails,
          loading: true,
          error: null,
        },
      };
    case ActionType.GET_BULK_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        bulkPaymentDetails: {
          ...state.bulkPaymentDetails,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GET_BULK_PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        bulkPaymentDetails: {
          ...state.bulkPaymentDetails,
          data: payload,
          error: null,
          loading: false,
        },
      };

    case ActionType.CLEAR_BULK_PAYMENT_DETAILS:
      return {
        ...state,
        bulkPaymentDetails: {
          ...state.bulkPaymentDetails,
          error: null,
          loading: false,
          data: null,
        },
      };

    default:
      return null;
  }
};
