export default {
  returnSavingsDurationArray: {
    loadingOperator: false,
    success: false,
    message: '',
    operatorError: null,
    operatorData: null,
  },

  savingList: {
    loading: false,
    success: false,
    message: '',
    error: null,
    data: {},
  },

  savingsTransferConfirmation: {
    data: null,
    loading: false,
    error: null,
  },

  executeSavingsPlan: {
    loading: false,
    error: null,
    data: null,
  },

  savingsCancelConfirmation: {
    loading: false,
    success: false,
    message: '',
    error: null,
    data: null,
  },

  savingsCancelPlan: {
    loading: false,
    success: false,
    message: '',
    error: null,
    data: {},
  },
};
