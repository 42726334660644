/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Divider, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SavingsIcon from 'assets/images/Icon.svg';
import requestLoanImg from 'assets/images/microloan/request-loan.svg';
import rightArrow from 'assets/images/microloan/right-arrow.svg';
import calendarImg from 'assets/images/microloan/calendar.svg';
import loanPaidImg from 'assets/images/microloan/paid-loan.svg';
import './style.scss';
import moment from 'moment';
import DateIcon from 'assets/images/date_icon.svg';

export const CustomTag = ({ title, className }) => (
  <span
    className={`${className} align-self-center medium-padding-left medium-padding-right`}
  >
    {global.translate(title)}
  </span>
);

const SingleLoanView = ({
  data,
  onClick,
  detail,
  cardLabels,
  setSelectedItem,
}) => {
  return (
    <div
      className="display-flex-column"
      onClick={() => {
        onClick();
        setSelectedItem(data);
      }}
      style={{ cursor: 'pointer' }}
    >
      <div className="display-flex-row small-padding-top small-padding-bottom">
        <div className="service-image-b">
          <Image src={SavingsIcon} />
        </div>
        <div className="display-flex-row space-between align-self-center">
          <div className="display-flex-column">
            <span>{`${data?.Currency} ${data?.Capital}`}</span>
            <div className="display-flex-row align-self-center">
              {data?.Status === '0' && data?.ClosingDate && (
                <Image src={DateIcon} />
              )}
              <span
                className="small-margin-left align-self-end"
                style={
                  data?.Status === '0' ? { color: '#1F8B57' } : {}
                }
              >
                {global.translate(
                  `${
                    cardLabels?.filter(
                      label => label.status === data?.Status,
                    )[0]?.title
                  }`,
                )}
                {data?.Status === '0' &&
                  data?.ClosingDate &&
                  `: ${moment(data?.ClosingDate).format(
                    'YYYY-MM-DD',
                  )}`}
              </span>
            </div>
          </div>
          <div className="display-flex-row justify-content-flex-end small-padding-right">
            {data?.Status === '1' && (
              <CustomTag
                title="Closed"
                className={'custom-tag-success'}
              />
            )}

            {data?.Status === '3' && (
              <CustomTag
                title="Canceled"
                className={'custom-tag-failed'}
              />
            )}

            <div className="arrow-img medium-margin-left">
              <Image src={rightArrow} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default SingleLoanView;
