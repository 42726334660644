import {
  ADD_STORE_AGENTS_ERROR,
  ADD_STORE_AGENTS_START,
  ADD_STORE_AGENTS_SUCCESS,
  CLEAR_ADD_STORE_AGENT,
} from 'constants/action-types/stores/addStoreAgents';
import addStoreAgents from 'redux/actions/stores/addStoreAgents';
import addStore from 'routes/stores/addStore';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_STORE_AGENTS_START:
      return {
        ...state,
        addStoreAgents: {
          ...state.addStoreAgents,
          loading: true,
          error: null,
          success: false,
        },
      };
    case ADD_STORE_AGENTS_ERROR:
      return {
        ...state,
        addStoreAgents: {
          ...state.addStoreAgents,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case ADD_STORE_AGENTS_SUCCESS:
      return {
        ...state,
        addStoreAgents: {
          ...state.addStoreAgents,
          error: null,
          loading: false,
          data: payload.data,
          success: true,
        },

        listStoreAgents: {
          ...state.listStoreAgents,
          data: [...state.listStoreAgents.data, payload.data],
        },
      };
    case CLEAR_ADD_STORE_AGENT:
      return {
        ...state,
        addStoreAgents: {
          ...state,
          addStoreAgents: {
            ...state.addStoreAgents,
            error: null,
            loading: false,
            success: false,
            data: null,
          },
        },
      };

    default:
      return null;
  }
};
