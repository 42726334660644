import React, { useState } from 'react';
import { useHistory } from 'react-router';
import DashboardLayout from 'components/common/DashboardLayout';
import WelcomeBar from 'components/Dashboard/WelcomeSection';
import isAppDisplayedInWebView from 'helpers/isAppDisplayedInWebView';
import GoBack from 'components/common/GoBack';
import NoResultFound from './NoResultFound';
import EmptySavingIcon from 'assets/images/Empty_state_ Savings.svg';
import SavingsIcon from 'assets/images/Icon.svg';
import loadTransactions from 'assets/images/transactions/load-transactions.svg';
import {
  Button,
  Divider,
  Icon,
  Image,
  Pagination,
} from 'semantic-ui-react';
import { NCardComponent } from 'components/common/BottomMenu/Card';
import SavingsTermsModal from './savingsTerms';
import CreateSavingsPlanModal from './createSavingsPlan';
import moment from 'moment';
import ConfirmationModal from './confirmationModal';
import PINConfirmationModal from 'components/common/PINConfirmationModal';
import SavingPlanDetails from './savingsPlanDetails';
import ClaimPrematurePayout from './claimPrematurePayout';
import LoanPlaceholder from 'assets/images/microloan/single-loan-placeholder.svg';
import ActiveSavingsIcon from 'assets/images/Icons -_ Savingsactive_savings_icon.svg';
import SingleLoanView from './View/index';
import './index.scss';

const Savings = ({
  operator,
  savings,
  cardLabels,
  openSavingsTerms,
  onOpenSavingsTerms,
  onCloseSavingsTerms,
  openCreateSavingsPlan,
  onCloseCreateSavingsPlan,
  onAgreeTermsAndCondition,
  currentWallet,
  setCurrentWallet,
  walletList,
  onHandleSelectDuration,
  selectedDuration,
  form,
  onHandleChangeInput,
  onCreateSaving,
  savingsTransferConfirmation,
  transferConfirmation,
  openSavingsTransferConfirmation,
  cancelSavingsCreation,
  openPinModal,
  setOpenPinModal,
  PIN,
  setPIN,
  executeSavingsPlan,
  onExecuteSavingsPlan,
  openClaimPL,
  onOpenClaimPrematurePayout,
  onCloseClaimPrematurePayout,
  savingsCancelConfirmation,
  onSavingsCancelConfirmation,
  selectedItem,
  setSelectedItem,
  savingsCancelPlan,
  onHandleSavingsCancelPlan,
  openSavingsDetails,
  setOpenSavingsDetails,
  openCancelSavingPinModal,
  setOpenCancelSavingsPinModal,
  setCancelSavingPIN,
  cancelSavingsPIN,
  setOpenClaimPL,
  activePage,
  onChangePage,
}) => {
  const history = useHistory();
  const onClickHandler = () => history.goBack();
  const onCloseSavingsDetails = () => {
    setOpenSavingsDetails(false);
  };

  const componentArray = Array.from(
    { length: 6 },
    (_, index) => index,
  );

  return (
    <DashboardLayout>
      <WelcomeBar>
        <div className="head-content">
          {!isAppDisplayedInWebView() && (
            <div className="go-back">
              <GoBack style onClickHandler={onClickHandler} />
            </div>
          )}
          <h2 className="head-title">
            {operator?.OperatorName || global.translate('Savings')}
          </h2>
          <div className="clear" />
        </div>
      </WelcomeBar>

      {savings?.loading === false && savings?.Data && (
        <div className="savings-list-container">
          <div className="info-part">
            <div
              className="display-flex-row"
              style={{ width: '50%' }}
            >
              <div>
                <Image src={ActiveSavingsIcon} />
              </div>

              <div className="display-flex-column medium-margin-left">
                <h3 style={{ marginBottom: '5px' }}>
                  {global.translate('Savings plan')}
                </h3>
                <span>
                  {global.translate(
                    'Choose a plan that best suits your needs',
                  )}
                </span>
              </div>
            </div>

            <Button
              onClick={onOpenSavingsTerms}
              className="simple-btn"
            >
              <span className="medium-margin-right">
                {global.translate('Get started')}
              </span>
              <Icon name="plus" />
            </Button>
          </div>
          <Divider />
          {savings?.Data?.map(savings => (
            <SingleLoanView
              data={savings}
              onClick={() => {
                setOpenSavingsDetails(true);
              }}
              setSelectedItem={setSelectedItem}
              cardLabels={cardLabels}
            />
          ))}

          <Pagination
            activePage={activePage}
            onPageChange={onChangePage}
            totalPages={savings?.Meta?.TotalPages}
            ellipsisItem={null}
          />
        </div>
      )}

      <SavingPlanDetails
        open={openSavingsDetails}
        data={selectedItem}
        cardLabels={cardLabels}
        onClose={onCloseSavingsDetails}
        onOpenClaimPrematurePayout={onOpenClaimPrematurePayout}
      />

      {savings.loading && (
        <div className="loan-list-container">
          {componentArray.map(item => (
            <div key={item} className="loan-placeholder">
              <Image
                className="animate-placeholder"
                src={LoanPlaceholder}
              />
            </div>
          ))}
        </div>
      )}

      {savings.loading === false &&
        !savings?.Data &&
        !savings?.error && (
          <NoResultFound
            title={'You do not have any savings yet.'}
            subTitle={
              'We have made it easy for you to start making money by saving the funds you already have in your wallets.'
            }
            Icon={EmptySavingIcon}
            open={onOpenSavingsTerms}
          />
        )}

      {operator && (
        <SavingsTermsModal
          bodyText={operator?.Terms}
          open={openSavingsTerms}
          onClose={onCloseSavingsTerms}
          onAgreeTermsAndCondition={onAgreeTermsAndCondition}
        />
      )}

      <ConfirmationModal
        open={openSavingsTransferConfirmation}
        data={transferConfirmation}
        close={cancelSavingsCreation}
        setOpenPinModal={setOpenPinModal}
        setOpenCancelSavingsPinModal={setOpenCancelSavingsPinModal}
        cancelData={savingsCancelConfirmation?.data}
      />

      <CreateSavingsPlanModal
        open={openCreateSavingsPlan}
        onClose={onCloseCreateSavingsPlan}
        openCreateSavingsPlan={openCreateSavingsPlan}
        currentWallet={currentWallet}
        setCurrentWallet={setCurrentWallet}
        walletList={walletList}
        durationList={operator?.DurationArray}
        onHandleSelectDuration={onHandleSelectDuration}
        selectedDuration={selectedDuration}
        form={form}
        onCreateSaving={onCreateSaving}
        onHandleChangeInput={onHandleChangeInput}
        savingsTransferConfirmation={savingsTransferConfirmation}
      />

      <ClaimPrematurePayout
        open={openClaimPL}
        currentWallet={currentWallet}
        onClose={onCloseClaimPrematurePayout}
        walletList={walletList}
        setCurrentWallet={setCurrentWallet}
        selectedItem={selectedItem}
        savingsCancelConfirmation={savingsCancelConfirmation}
        onSavingsCancelConfirmation={onSavingsCancelConfirmation}
      />

      <PINConfirmationModal
        open={openPinModal}
        setOpen={setOpenPinModal}
        setPIN={setPIN}
        PIN={PIN}
        onClose={() => {
          setOpenPinModal(false);
        }}
        loading={executeSavingsPlan?.loading}
        onPinConfirm={onExecuteSavingsPlan}
      />

      <PINConfirmationModal
        open={openCancelSavingPinModal}
        setOpen={setOpenCancelSavingsPinModal}
        setPIN={setCancelSavingPIN}
        PIN={cancelSavingsPIN}
        onClose={() => {
          setOpenCancelSavingsPinModal(false);
        }}
        loading={savingsCancelPlan?.loading}
        onPinConfirm={onHandleSavingsCancelPlan}
      />
    </DashboardLayout>
  );
};

export default Savings;
