export const ADD_MONEY_FROM_CREDIT_CARD_START =
  'ADD_MONEY_FROM_CREDIT_CARD_START';
export const ADD_MONEY_FROM_CREDIT_CARD_SUCCESS =
  'ADD_MONEY_FROM_CREDIT_CARD_SUCCESS';
export const ADD_MONEY_FROM_CREDIT_CARD_ERROR =
  'ADD_MONEY_FROM_CREDIT_CARD_ERROR';
export const ADD_MONEY_FROM_CREDIT_CARD_END =
  'ADD_MONEY_FROM_CREDIT_CARD_END';
export const CLEAR_ADD_MONEY_FROM_CREDIT_CARD_STORE =
  'CLEAR_ADD_MONEY_FROM_CREDIT_CARD_STORE';
