import React from 'react';

const Separator = ({ title }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          background: '#C0C0C0',
          height: '1px',
          width: '200px',
          marginTop: '10px',
        }}
      ></div>
      <span
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          color: '#C0C0C0',
          fontWeight: '400',
          fontSize: '12px',
        }}
      >
        {title}
      </span>
      <div
        style={{
          background: '#C0C0C0',
          height: '1px',
          width: '200px',
          marginTop: '10px',
        }}
      ></div>
    </div>
  );
};

export default Separator;
