import {
  CREATE_SAVINGS_TRANSFER_CONFIRMATION_START,
  CREATE_SAVINGS_TRANSFER_CONFIRMATION_SUCCESS,
  CREATE_SAVINGS_TRANSFER_CONFIRMATION_ERROR,
  CLEAR_CREATE_SAVINGS_TRANSFER_CONFIRMATION,
} from 'constants/action-types/savings';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_SAVINGS_TRANSFER_CONFIRMATION_START:
      return {
        ...state,
        savingsTransferConfirmation: {
          ...state.savingsTransferConfirmation,
          loading: true,
          error: null,
        },
      };
    case CREATE_SAVINGS_TRANSFER_CONFIRMATION_ERROR:
      return {
        ...state,
        savingsTransferConfirmation: {
          ...state.savingsTransferConfirmation,
          data: null,
          error: payload,
          loading: false,
        },
      };
    case CREATE_SAVINGS_TRANSFER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        savingsTransferConfirmation: {
          ...state.savingsTransferConfirmation,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case CLEAR_CREATE_SAVINGS_TRANSFER_CONFIRMATION:
      return {
        ...state,
        savingsTransferConfirmation: {
          ...state.savingsTransferConfirmation,
          // ...payload,
          loading: false,
          error: null,
          data: null,
        },
      };
    default:
      return null;
  }
};
