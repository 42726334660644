import {
  DISPUTE_TRANSACTION_RESET,
  DISPUTE_TRANSACTION_START,
  DISPUTE_TRANSACTION_SUCCESS,
  DISPUTE_TRANSACTION_ERROR,
} from 'constants/action-types/transactions/recallDisputeTransaction';

export default (state, { type, payload }) => {
  switch (type) {
    case DISPUTE_TRANSACTION_RESET:
      return {
        ...state,
        disputeTX: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case DISPUTE_TRANSACTION_START:
      return {
        ...state,
        disputeTX: {
          ...state.disputeTX,
          loading: true,
          error: null,
        },
      };
    case DISPUTE_TRANSACTION_ERROR:
      return {
        ...state,
        disputeTX: {
          ...state.disputeTX,
          error: payload,
          loading: false,
        },
      };
    case DISPUTE_TRANSACTION_SUCCESS:
      return {
        ...state,
        disputeTX: {
          ...state.disputeTX,
          error: null,
          loading: false,
          data: payload,
        },
      };

    default:
      return null;
  }
};
