export const GET_BILLS_CATEGORIES_START =
  'GET_BILLS_CATEGORIES_START';
export const GET_BILLS_CATEGORIES_SUCCESS =
  'GET_BILLS_CATEGORIES_SUCCESS';
export const GET_BILLS_CATEGORIES_ERROR =
  'GET_BILLS_CATEGORIES_ERROR';
export const GET_BILLS_CATEGORIES_END = 'GET_BILLS_CATEGORIES_END';

export const VERIFY_GATEWAY_ACCOUNT_START =
  'VERIFY_GATEWAY_ACCOUNT_START';
export const VERIFY_GATEWAY_ACCOUNT_SUCCESS =
  'VERIFY_GATEWAY_ACCOUNT_SUCCESS';
export const VERIFY_GATEWAY_ACCOUNT_ERROR =
  'VERIFY_GATEWAY_ACCOUNT_ERROR';
export const VERIFY_GATEWAY_ACCOUNT_END =
  'VERIFY_GATEWAY_ACCOUNT_END';
export const CLEAR_VERIFY_GATEWAY_ACCOUNT =
  'CLEAR_VERIFY_GATEWAY_ACCOUNT';
