export default {
  createWallet: {
    error: null,
    loading: false,
    success: false,
  },
  editWallet: {
    error: null,
    loading: false,
    success: false,
  },
  defaultWallet: {
    error: null,
    loading: false,
    data: null,
  },
  currenciesList: {
    data: null,
    loading: false,
    error: null,
  },
};
