import cardLessAtm from './cardLessAtm';
import CashoutForMyself from './CashoutForMyself';
import cashOut from './cashOut';
import toSomeOneElse from './toSomeOneElse';
import forMyself from './forMyself';

export default [
  cardLessAtm,
  cashOut,
  CashoutForMyself,
  toSomeOneElse,
  forMyself,
];
