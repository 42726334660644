import React, { useState, useEffect } from 'react';
import { Image, Input } from 'semantic-ui-react';
import { FaCaretDown } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import WalletDropDown from 'components/common/WalletDropDown';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import CountryDropdown from 'components/common/Dropdown/CountryDropdown';
import ReusableDropdown from 'components/common/Dropdown/ReusableDropdown';
import SavedAccountDropdown from 'components/common/Dropdown/SavedAccountDropdown';
import InputLoader from 'assets/images/LoaderRectangle.svg';
import {
  MNO_OPERATOR_TYPE,
  BANK_OPERATOR_TYPE,
} from 'constants/gimacOperationType';

export function FirstProcessBoxForm({
  bankAccount,
  amount,
  onChangeBankAccount,
  onChangeAmount,
  walletList,
  setCurrentOption,
  currentOption,
  countries,
  selectedCountry,
  handleInputChange,
  loadProvidersList,
  setCurrentProviderOption,
  providersListOption,
  currentProviderOption,
  merchantCode,
  onChangeCode,
}) {
  return (
    <>
      <div>
        {walletList?.length !== 0 ? (
          <>
            <div className="cash-out-title">
              {global.translate('Select wallet')}
            </div>
            <div className="wallet-select">
              <WalletDropDown
                walletList={walletList}
                setCurrentOption={setCurrentOption}
                currentOption={currentOption}
              />
            </div>
          </>
        ) : (
          <div className="load-data">
            <Image
              src={LoadWalletImg}
              className="animate-placeholder"
            />
          </div>
        )}
      </div>

      <div>
        <div className="cash-out-title">
          {global.translate('Select a destination country')}
        </div>

        <div className="wallet-select">
          <CountryDropdown
            className="country-dropdwn-search-store"
            options={countries}
            currentOption={selectedCountry}
            onChange={handleInputChange}
            search
          />
        </div>
      </div>

      <div>
        <div className="wallet-select">
          <p className="choose-dest-country">
            {global.translate('Choose an  operator')}
            &nbsp;
            <strong>
              {(currentOption && currentOption?.CountryName) ||
                currentOption?.Title}
            </strong>
          </p>
          {!loadProvidersList && providersListOption ? (
            <ReusableDropdown
              options={providersListOption}
              currentOption={currentProviderOption}
              setCurrentOption={setCurrentProviderOption}
              search
              placeholder={global.translate('Select a provider')}
            />
          ) : (
            <div className="load-data">
              <Image
                className="animate-placeholder loader-others"
                src={InputLoader}
              />
            </div>
          )}
        </div>
      </div>

      {currentProviderOption?.Category === MNO_OPERATOR_TYPE ? (
        <>
          <div>
            <div className="cash-out-title">
              {global.translate('Merchant code')}
            </div>
            <div className="operator-input">
              <Input
                value={merchantCode}
                onChange={onChangeCode}
                name="text"
                placeholder={global.translate('Merchant code')}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {currentProviderOption?.Category === BANK_OPERATOR_TYPE ? (
        <>
          <div>
            <div className="cash-out-title">
              {global.translate('Merchant code')}
            </div>
            <div className="operator-input">
              <Input
                name="text"
                value={bankAccount}
                onChange={onChangeBankAccount}
                placeholder={global.translate('Merchant code')}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div>
        <div className="cash-out-title">
          {global.translate('Amount')}
        </div>
        <div className="operator-input">
          <Input
            label={{
              basic: true,
              content: currentOption?.CurrencyCode,
            }}
            labelPosition="right"
            value={amount}
            onChange={onChangeAmount}
            name="text"
            placeholder={global.translate('0.0')}
          />
        </div>
      </div>
    </>
  );
}

export const LastProcessForm = () => {
  const getResult = useSelector(
    gimac => gimac.gimac.transferConfirmation.data,
  );

  return (
    <>
      <div className="item-decider">
        <div className="item-decider__box">
          <span className="left">{global.translate('Amount')}</span>
          <span className="right">{getResult?.Amount}</span>
        </div>
        <div className="item-decider__box">
          <span className="left">{global.translate('Fees')}</span>
          <span className="right">{getResult?.Fees}</span>
        </div>
        <div className="item-decider__box">
          <span className="left">
            {global.translate('Exchange fees')}
          </span>
          <span className="right">{getResult?.ExchangeFees}</span>
        </div>
        <div className="item-decider__box">
          <span className="left">
            {global.translate('Exchange rate')}
          </span>
          <span className="right">{getResult?.ExchangeRate}</span>
        </div>
        <div className="item-decider__box">
          <span className="left">{global.translate('Taxes')}</span>
          <span className="right">{getResult?.Taxes}</span>
        </div>
        <div className="item-decider__box">
          <span className="left">
            {global.translate('Total amount')}
          </span>
          <span className="right">{getResult?.TotalAmount}</span>
        </div>
      </div>
    </>
  );
};
