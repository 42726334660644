import {
  CREATE_SAVINGS_TRANSFER_CONFIRMATION_START,
  CREATE_SAVINGS_TRANSFER_CONFIRMATION_SUCCESS,
  CREATE_SAVINGS_TRANSFER_CONFIRMATION_ERROR,
  CLEAR_CREATE_SAVINGS_TRANSFER_CONFIRMATION,
} from 'constants/action-types/savings';
import apiAction from 'helpers/apiAction';

export default data => dispatch =>
  dispatch(
    apiAction({
      method: 'post',
      url: '/SavingsTransferConfirmation',
      data,
      onStart: () => dispatch =>
        dispatch({
          type: CREATE_SAVINGS_TRANSFER_CONFIRMATION_START,
        }),
      onSuccess: res => dispatch => {
        const payload = Array.isArray(res) ? res[0] : res || {};
        return dispatch({
          type: CREATE_SAVINGS_TRANSFER_CONFIRMATION_SUCCESS,
          payload,
        });
      },
      onFailure: error => dispatch => {
        const err = Array.isArray(error) ? error[0] : error || {};
        return dispatch({
          type: CREATE_SAVINGS_TRANSFER_CONFIRMATION_ERROR,
          payload: {
            ...err,
          },
        });
      },
    }),
  );

export const clearSaveSavingsTransferConfirmation = () => dispatch => {
  return dispatch({
    type: CLEAR_CREATE_SAVINGS_TRANSFER_CONFIRMATION,
  });
};
