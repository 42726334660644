import React, { useEffect, useState } from 'react';
import bankIcon from 'assets/images/bankIcon.svg';
import InputLoader from 'assets/images/LoaderRectangle.svg';
import { Button, Image, Input, Dropdown } from 'semantic-ui-react';
import { BANK_PROVIDERS_TYPE } from 'constants/providersTypes';
import ReusableDropdown from 'components/common/Dropdown/ReusableDropdown';
import DynamicForm from 'components/common/DynamicForm';
import { clearVerifyGateway } from 'redux/actions/payBills/verifyGatewayAccount';
import { useDispatch } from 'react-redux';

const ToSomeoneElseAccount = props => {
  const {
    loading,
    setCurrentPage,
    providersList,
    currentProviderOption,
    setCurrentProviderOption,
    form,
    currentOption,
    onHandleChangeTransferFundsToBankInput,
    linkedBankAccounts,
    bankOptions,
    setBankOptions,
    selectedBankAccount,
    setSelectedBankAccount,
    userData,
    setForm,
    countries,
    selectedCountry,
    countryLoading,
    setSelectedCountry,
    defaultCountry,
    setMobileMoneyOperatorForm,
    mobileMoneyOperatorForm,
    getSelectedCountry,
    setGetSelectedCountry,
    getDefaultProvider,
    setGetDefaultProvider,
    onSubmitGatewayToSomeoneElse,
    onVerifyGatewayAccount,
    verifiedGatewayAccountData,
    wallet,
  } = props;
  const dispatch = useDispatch();
  const [enableNextProcess, setEnableNextProcess] = useState(true);
  const [getTelco, setGetTelco] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const newList = [];
    const secondList = [];
    const providers = providersList?.data?.map((item, index) => {
      if (item?.TargetType === BANK_PROVIDERS_TYPE) {
        newList.push(item);
        secondList.push({
          key: index,
          BrandUnikID: item.BrandUnikID,
          Category: item.Category,
          Img: item.Logo,
          OperatorID: item.OperatorID,
          OperatorName: item.OperatorName,
          PhoneAreaCode: item.PhoneAreaCode,
          Title: item.OperatorName,
          ShortName: item.ShortName,
          ActionType: item.ActionType,
        });
      }
    });
    setGetTelco(newList);
    setGetDefaultProvider(secondList[0]);
  }, [providersList, selectedCountry]);

  const selectBankHandler = (_, { value }) => {
    setSelectedBankAccount(value);
  };

  useEffect(() => {
    if (
      (currentProviderOption !== undefined &&
        mobileMoneyOperatorForm?.phoneNumber !== ' ') ||
      (mobileMoneyOperatorForm?.amount !== '' &&
        mobileMoneyOperatorForm?.amount !== undefined)
    ) {
      setEnableNextProcess(false);
    } else if (mobileMoneyOperatorForm?.accountNumber !== '') {
      setEnableNextProcess(false);
    }
  }, [
    currentProviderOption,
    mobileMoneyOperatorForm?.phoneNumber,
    mobileMoneyOperatorForm?.amount,
  ]);

  useEffect(() => {
    const newList = [];
    const findCountry = countries?.map(item => {
      if (
        item?.CountryCode === selectedCountry ||
        item?.CountryCode === defaultCountry
      ) {
        newList.push(item);
      }
    });
    setGetSelectedCountry(newList[0]);
  }, [selectedCountry, defaultCountry]);

  useEffect(() => {
    const newList = [];
    const countriesList = countries?.map((item, index) => {
      newList.push({
        key: item?.CountryCode,
        text: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
            key={index}
          >
            <img
              src={item?.Flag}
              style={{
                width: '15px',
              }}
            />
            <span
              style={{
                fontSize: '12',
                marginLeft: '10px',
                marginTop: '3px',
              }}
            >
              {item?.CountryName}
            </span>
          </div>
        ),
        value: item?.CountryCode,
      });
    });
    setCountriesList(newList);
  }, [countries]);

  return (
    <>
      <div className="card-box">
        <div className="card-box__items-details">
          <Image src={bankIcon} />
          <div className="card-box__items-left">
            <div className="items_content">
              <span className="btn-title__top">
                {global.translate(`To someone else's account`)}
              </span>
              <span className="btn-title__bottom">
                {global.translate('Transfer funds to a bank account')}
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div className="card-box__field">
        <span className="card-title">
          {global.translate('Select a destination country')}
        </span>
        <div className="input-field">
          <Dropdown
            selection
            loading={countryLoading}
            options={countriesList}
            value={selectedCountry}
            defaultValue={defaultCountry}
            placeholder={global.translate('Select a country')}
            size="large"
            style={{ width: '100%' }}
            onChange={(data, item) => {
              setSelectedCountry(item.value);
              setMobileMoneyOperatorForm({
                destinationCountry: item.value,
              });
            }}
          />
        </div>
      </div>

      {providersList ? (
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Select operator')}
          </span>
          <div className="input-field">
            <ReusableDropdown
              options={getTelco}
              currentOption={currentProviderOption}
              setCurrentOption={setCurrentProviderOption}
              loading={providersList?.loading}
              placeholder={global.translate('Select operator')}
            />
          </div>
        </div>
      ) : (
        <div className="load-data">
          <Image
            className="animate-placeholder loader-others"
            src={InputLoader}
          />
        </div>
      )}

      <DynamicForm
        fixedHandleInputLabelPosition={'right'}
        jsonFields={currentProviderOption?.Fields}
        verifiedAccountFields={verifiedGatewayAccountData}
        handleChangeInputForm={setMobileMoneyOperatorForm}
        fixedHandleInputChange={
          onHandleChangeTransferFundsToBankInput
        }
        fixedHandleInputValue={mobileMoneyOperatorForm?.amount}
        fixedHandleInputLabel={{
          basic: true,
          content: currentOption?.CurrencyCode,
        }}
        country={getSelectedCountry}
        verifyAccount={
          mobileMoneyOperatorForm?.inputField?.verifyAccount
        }
      />

      <div
        className="bottom-transition__box"
        style={{ marginTop: '-15px' }}
      >
        <Button
          id="btn-back"
          onClick={() => {
            setCurrentPage(0);
            setCurrentProviderOption();
            clearVerifyGateway()(dispatch);
          }}
        >
          {global.translate('Back')}
        </Button>

        {mobileMoneyOperatorForm?.inputField?.verifyAccount ? (
          <Button
            onClick={onVerifyGatewayAccount}
            loading={verifiedGatewayAccountData.loading}
            style={{ marginLeft: '10px' }}
          >
            {global.translate('Next')}
          </Button>
        ) : (
          <Button
            onClick={onSubmitGatewayToSomeoneElse}
            loading={loading}
            disabled={enableNextProcess || wallet?.Balance === '0.00'}
            style={{ marginLeft: '10px' }}
          >
            {global.translate('Proceed')}
          </Button>
        )}
      </div>
    </>
  );
};

export default ToSomeoneElseAccount;
