import * as ActionType from 'constants/action-types/gimac';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.GIMAC_PAY_BILLS_START:
      return {
        ...state,
        payBills: {
          ...state.payBills,
          loading: true,
          error: null,
        },
      };
    case ActionType.GIMAC_PAY_BILLS_SUCCESS:
      return {
        ...state,
        payBills: {
          ...state.payBills,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GIMAC_PAY_BILLS_FAILURE:
      return {
        ...state,
        payBills: {
          ...state.payBills,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GIMAC_PAY_BILLS_CLEAR:
      return {
        ...state,
        payBills: {
          ...state.payBills,
          data: null,
          error: null,
          loading: false,
        },
        transferConfirmation: {
          ...state.transferConfirmation,
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
