import {
  CANCEL_SAVINGS_PLAN_START,
  CANCEL_SAVINGS_PLAN_SUCCESS,
  CANCEL_SAVINGS_PLAN_ERROR,
  CLEAR_CANCEL_SAVINGS_PLAN,
} from 'constants/action-types/savings';

export default (state, { type, payload }) => {
  switch (type) {
    case CANCEL_SAVINGS_PLAN_START:
      return {
        ...state,
        savingsCancelPlan: {
          ...state.savingsCancelPlan,
          loading: true,
          error: null,
        },
      };
    case CANCEL_SAVINGS_PLAN_ERROR:
      return {
        ...state,
        savingsCancelPlan: {
          ...state.savingsCancelPlan,
          data: null,
          error: payload,
          loading: false,
        },
      };
    case CANCEL_SAVINGS_PLAN_SUCCESS:
      return {
        ...state,
        savingsCancelPlan: {
          ...state.savingsCancelPlan,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case CLEAR_CANCEL_SAVINGS_PLAN:
      return {
        ...state,
        savingsCancelPlan: {
          ...state.savingsCancelPlan,
          loading: false,
          error: null,
          data: null,
        },
      };
    default:
      return null;
  }
};
