export const SWITCH_TO_BUSINESS_ACCOUNT_START =
  'SWITCH_TO_BUSINESS_ACCOUNT_START';
export const SWITCH_TO_BUSINESS_ACCOUNT_FAILURE =
  'SWITCH_TO_BUSINESS_ACCOUNT_FAILURE';
export const SWITCH_TO_BUSINESS_ACCOUNT_SUCCESS =
  'SWITCH_TO_BUSINESS_ACCOUNT_SUCCESS';
export const CLEAR_SWITCH_ACCOUNT_DATA = 'CLEAR_SWITCH_ACCOUNT_DATA';

export const GET_BUSINESS_TYPE_START = 'GET_BUSINESS_TYPE_START';
export const GET_BUSINESS_TYPE_SUCCESS = 'GET_BUSINESS_TYPE_SUCCESS';
export const GET_BUSINESS_TYPE_FAILURE = 'GET_BUSINESS_TYPE_FAILURE';
