import { toast } from 'react-toastify';
import {
  SEND_FUNDS_TO_GATEWAY_ERROR,
  SEND_FUNDS_TO_GATEWAY_START,
  SEND_FUNDS_TO_GATEWAY_SUCCESS,
  CLEAR_SEND_FUNDS_TO_GATEWAY_STORE,
} from 'constants/action-types/moneyTransfer';
import apiAction from 'helpers/apiAction';

export default data => dispatch => {
  return dispatch(
    apiAction({
      method: 'post',
      url: '/SendFundsToGateway',
      data,
      onStart: () => dispatch =>
        dispatch({
          type: SEND_FUNDS_TO_GATEWAY_START,
          payload: data,
        }),
      onSuccess: data => dispatch => {
        toast.success(global.translate(data[0].Description));
        return dispatch({
          type: SEND_FUNDS_TO_GATEWAY_SUCCESS,
          payload: data,
        });
      },
      onFailure: error => dispatch => {
        toast.error(global.translate(error[0].Description));
        return dispatch({
          type: SEND_FUNDS_TO_GATEWAY_ERROR,
          payload: error,
        });
      },
    }),
  );
};

export const clearSendFundToGatewayStore = () => dispatch => {
  dispatch({
    type: CLEAR_SEND_FUNDS_TO_GATEWAY_STORE,
  });
};
