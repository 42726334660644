/* eslint-disable camelcase */
export const ADD_PUBLICITY_START = 'ADD_PUBLICITY_START';
export const ADD_PUBLICITY_SUCCESS = 'ADD_PUBLICITY_SUCCESS';
export const ADD_PUBLICITY_ERROR = 'ADD_PUBLICITY_ERROR';
export const ADD_PUBLICITY_END = 'ADD_PUBLICITY_END';
export const CLEAR_ADD_PUBLICITY_STORE = 'CLEAR_ADD_PUBLICITY_STORE';

export const GET_PUBLICITIES_START = 'GET_PUBLICITIES_START';
export const GET_PUBLICITIES_SUCCESS = 'GET_PUBLICITIES_SUCCESS';
export const GET_PUBLICITIES_ERROR = 'GET_PUBLICITIES_ERROR';
export const GET_PUBLICITIES_END = 'GET_PUBLICITIES_END';
export const CLEAR_GET_PUBLICITIES_STORE =
  'CLEAR_GET_PUBLICITIES_STORE';

export const EXECUTE_PUBLICITY_START = 'EXECUTE_PUBLICITY_START';
export const EXECUTE_PUBLICITY_SUCCESS = 'EXECUTE_PUBLICITY_SUCCESS';
export const EXECUTE_PUBLICITY_ERROR = 'EXECUTE_PUBLICITY_ERROR';
export const EXECUTE_PUBLICITY_END = 'EXECUTE_PUBLICITY_END';
export const CLEAR_EXECUTE_PUBLICITY_STORE =
  'CLEAR_EXECUTE_PUBLICITY_STORE';

export const CALC_CAMPAING_COST_START = 'CALC_CAMPAING_COST_START';
export const CALC_CAMPAING_COST_SUCCESS =
  'CALC_CAMPAING_COST_SUCCESS';
export const CALC_CAMPAING_COST_ERROR = 'CALC_CAMPAING_COST_ERROR';
export const CALC_CAMPAING_COST_END = 'CALC_CAMPAING_COST_END';
export const CLEAR_CALC_CAMPAING_COST = 'CLEAR_CALC_CAMPAING_COST';

export const DELETE_CAMPAIGN_START = 'DELETE_CAMPAIGN_START';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';
export const DELETE_CAMPAIGN_END = 'DELETE_CAMPAIGN_END';
export const CLEAR_DELETE_CAMPAIGN = 'CLEAR_DELETE_CAMPAIGN';

export const UPDATE_CAMPAIGN_vIEWS_COUNT_START =
  'UPDATE_CAMPAIGN_vIEWS_COUNT_START';
export const UPDATE_CAMPAIGN_vIEWS_COUNT_SUCCESS =
  'UPDATE_CAMPAIGN_vIEWS_COUNT_SUCCESS';
export const UPDATE_CAMPAIGN_vIEWS_COUNT_ERROR =
  'UPDATE_CAMPAIGN_vIEWS_COUNT_ERROR';
export const UPDATE_CAMPAIGN_vIEWS_COUNT_END =
  'UPDATE_CAMPAIGN_vIEWS_COUNT_END';
