import * as ActionType from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.GET_ORGANIZATION_LIST_START:
      return {
        ...state,
        organizationList: {
          ...state.organizationList,
          loading: true,
          error: null,
        },
      };
    case ActionType.GET_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        organizationList: {
          ...state.organizationList,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GET_ORGANIZATION_LIST_ERROR:
      return {
        ...state,
        organizationList: {
          ...state.organizationList,
          data: payload,
          error: null,
          loading: false,
        },
      };

    case ActionType.CLEAR_GET_ORGANIZATION_LIST_STORE:
      return {
        ...state,
        organizationList: {
          ...state.organizationList,
          error: null,
          loading: false,
          data: null,
        },
      };

    default:
      return null;
  }
};
