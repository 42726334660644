export const ADD_MONEY_FROM_MOBILE_OPERATOR_START =
  'ADD_MONEY_FROM_MOBILE_OPERATOR_START';
export const ADD_MONEY_FROM_MOBILE_OPERATOR_SUCCESS =
  'ADD_MONEY_FROM_MOBILE_OPERATOR_SUCCESS';
export const ADD_MONEY_FROM_MOBILE_OPERATOR_ERROR =
  'ADD_MONEY_FROM_MOBILE_OPERATOR_ERROR';
export const ADD_MONEY_FROM_MOBILE_OPERATOR_END =
  'ADD_MONEY_FROM_MOBILE_OPERATOR_END';
export const CLEAR_ADD_MONEY_FROM_MOBILE_OPERATOR_STORE =
  'CLEAR_ADD_MONEY_FROM_CREDIT_CARD_STORE';

export const GATEWAY_ADD_MONEY_FEES_START =
  'GATEWAY_ADD_MONEY_FEES_START';
export const GATEWAY_ADD_MONEY_FEES_SUCCESS =
  'GATEWAY_ADD_MONEY_FEES_SUCCESS';
export const GATEWAY_ADD_MONEY_FEES_ERROR =
  'GATEWAY_ADD_MONEY_FEES_ERROR';
export const GATEWAY_ADD_MONEY_FEES_END =
  'GATEWAY_ADD_MONEY_FEES_END';
export const GATEWAY_ADD_MONEY_FEES_CLEAR =
  'GATEWAY_ADD_MONEY_FEES_CLEAR';
