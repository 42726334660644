import {
  WALLET_UPDATED,
  CLEAR_UPDATED_WALLET_DATA,
} from 'constants/events/wallet';

export default response => dispatch =>
  dispatch({
    type: WALLET_UPDATED,
    payload: response,
  });

export const clearWalletUpdateData = () => dispatch => {
  dispatch({
    type: CLEAR_UPDATED_WALLET_DATA,
  });
};
