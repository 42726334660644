/**
 * @param {object} error
 * @returns {boolean} true if the request should be retried
 */
export default error => {
  try {
    let shouldRetry = false;
    if (Array.isArray(error) && error.length) {
      error.forEach(err => {
        if (
          typeof err === 'object' &&
          err.Retry &&
          String(err.Retry || '').toLowerCase() === 'yes'
        ) {
          shouldRetry = true;
        }
      });
    } else if (error && Object.keys(error).length) {
      if (
        typeof error === 'object' &&
        error.Retry &&
        String(error.Retry || '').toLowerCase() === 'yes'
      ) {
        shouldRetry = true;
      }
    }
    return shouldRetry;
  } catch (e) {
    return false;
  }
};
