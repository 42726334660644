import * as ActionType from 'constants/action-types/gimac';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.TRANSFER_CONFIRMATION_START:
      return {
        ...state,
        transferConfirmation: {
          ...state.transferConfirmation,
          loading: true,
          error: null,
        },
      };
    case ActionType.TRANSFER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        transferConfirmation: {
          ...state.transferConfirmation,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.TRANSFER_CONFIRMATION_ERROR:
      return {
        ...state,
        transferConfirmation: {
          ...state.transferConfirmation,
          data: payload,
          error: null,
          loading: false,
        },
      };

    case ActionType.TRANSFER_CONFIRMATION_CLEAR: {
      return {
        ...state,
        transferConfirmation: {
          ...state.transferConfirmation,
          error: null,
          loading: false,
          data: null,
        },
      };
    }
    default:
      return null;
  }
};
