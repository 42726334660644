import * as ActionType from 'constants/action-types/gimac';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case ActionType.GET_CONTACT_EXTERNAL_START:
      return {
        ...state,
        savedContacts: {
          ...state.savedContacts,
          loading: true,
          error: null,
        },
      };
    case ActionType.GET_CONTACT_EXTERNAL_SUCCESS:
      return {
        ...state,
        savedContacts: {
          ...state.savedContacts,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.GET_CONTACT_EXTERNAL_ERROR:
      return {
        ...state,
        savedContacts: {
          ...state.savedContacts,
          error: payload,
          loading: false,
        },
      };
    case ActionType.GET_CONTACT_EXTERNAL_END:
      return {
        ...state,
        savedContacts: {
          ...state.savedContacts,
          data: [],
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
