import * as ActionType from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.EXECUTE_BULK_PAYMENT_OPERATION_START:
      return {
        ...state,
        executeBulkPaymentOperation: {
          ...state.executeBulkPaymentOperation,
          loading: true,
          error: null,
        },
      };
    case ActionType.EXECUTE_BULK_PAYMENT_OPERATION_SUCCESS:
      return {
        ...state,
        executeBulkPaymentOperation: {
          ...state.executeBulkPaymentOperation,
          data: payload,
          error: null,
          loading:
            payload?.OK === '200' || payload?.Error === '2016'
              ? true
              : false,
        },
      };
    case ActionType.EXECUTE_BULK_PAYMENT_OPERATION_ERROR:
      return {
        ...state,
        executeBulkPaymentOperation: {
          ...state.executeBulkPaymentOperation,
          data: payload,
          error: null,
          loading: false,
        },
      };

    default:
      return null;
  }
};
