import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Input,
  Dropdown,
  Checkbox,
  Radio,
  TextArea,
  Image,
} from 'semantic-ui-react';

const DynamicForm = ({
  handleChangeInputForm,
  fixedHandleInputChange,
  fixedHandleInputValue,
  fixedHandleInputLabel,
  fixedHandleInputLabelPosition,
  jsonFields,
  country,
  verifiedAccountFields,
  verifyAccount = false,
}) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState();
  const [meta, setMeta] = useState();

  const handleInputChange = (index, event, data) => {
    const values = [...fields];

    values[index].value =
      data?.value || event?.target?.value || data?.checked;

    setFields(values);

    const updatedForm = {};
    fields.forEach(field => {
      if (field?.type === 'tel') {
        updatedForm[
          field?.key
        ] = `${country?.PhoneAreaCode}${field.value}`;
      } else {
        updatedForm[field?.key] = field.value;
      }
    });
    handleChangeInputForm(prev => ({ ...prev, ...updatedForm }));
  };

  function convertStringFieldsToJson(jsonString) {
    if (!jsonString) {
      return null;
    }
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    const updatedForm = {};
    const convertedStringFields = convertStringFieldsToJson(
      jsonFields,
    );
    if (convertedStringFields?.meta) {
      const data = convertedStringFields?.data;
      const hasMeta = convertedStringFields?.meta;

      updatedForm['inputField'] = hasMeta;
      setMeta(hasMeta);
      setFields(data);
    } else {
      const fields =
        Array.isArray(convertedStringFields) &&
        convertedStringFields !== null &&
        convertedStringFields !== undefined
          ? convertedStringFields
          : convertedStringFields && [convertedStringFields];
      setFields(fields);
    }

    handleChangeInputForm(prev => ({ ...prev, ...updatedForm }));
  }, [jsonFields]);

  useEffect(() => {
    const updatedForm = {};
    if (Object.keys(verifiedAccountFields?.data).length !== 0) {
      const generateForm = verifiedAccountFields?.data[0]?.Data?.map(
        item => item,
      );

      updatedForm['inputField'] = {
        amountType: '',
        verifyAccount: '',
      };

      setFields(prev => [...prev, ...generateForm]);
    }
    handleChangeInputForm(prev => ({ ...prev, ...updatedForm }));
  }, [verifiedAccountFields?.data]);

  return (
    <div>
      {fields &&
        fields.map((field, index) =>
          field?.type === 'dropdown' ? (
            <div className="card-box__field" key={index}>
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <Dropdown
                  key={field?.key}
                  selection
                  style={{ width: '100%' }}
                  options={field?.options}
                  placeholder={global.translate(`${field?.label}`)}
                  value={field.value}
                  onChange={(event, data) =>
                    handleInputChange(index, event, data)
                  }
                />
              </div>
            </div>
          ) : field?.type === 'checkbox' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <Checkbox
                  key={field?.key}
                  checked={field.value}
                  onChange={(event, data) =>
                    handleInputChange(index, event, data)
                  }
                />
              </div>
            </div>
          ) : field?.type === 'radio' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <Radio
                  key={field?.key}
                  options={field?.options}
                  value={field.value}
                  onChange={(event, data) =>
                    handleInputChange(index, event, data)
                  }
                />
              </div>
            </div>
          ) : field?.type === 'textarea' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <TextArea
                  key={field?.key}
                  rows={field.rows}
                  value={field.value}
                  style={{ width: '100%' }}
                  placeholder={global.translate(`${field?.label}`)}
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
            </div>
          ) : field?.type === 'file' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <Input
                  key={field?.key}
                  placeholder={global.translate(`${field?.label}`)}
                  type="file"
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
            </div>
          ) : field?.type === 'tel' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div
                className="operator-input"
                style={{ marginTop: '10px' }}
              >
                <Input
                  key={field?.key}
                  placeholder={global.translate(`${field?.label}`)}
                  type={field?.type}
                  value={field.value}
                  onChange={event => handleInputChange(index, event)}
                  className="custom-input-image"
                >
                  <Image src={country?.Flag} />
                  <span>{country?.PhoneAreaCode}</span>
                  <input />
                </Input>
              </div>
            </div>
          ) : field?.type === 'text' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <Input
                  key={field?.key}
                  placeholder={global.translate(`${field?.label}`)}
                  type={field?.type}
                  min={field.min}
                  max={field.max}
                  value={field.value}
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
            </div>
          ) : (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate(`${field?.label}`)}
              </span>
              <div className="input-field">
                <Input
                  key={field?.key}
                  placeholder={global.translate(`${field?.label}`)}
                  type={field?.type}
                  min={field.min}
                  max={field.max}
                  value={field.value}
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
            </div>
          ),
        )}

      {!verifyAccount && (
        <>
          {!meta?.amountType || meta?.amountType === 'custom' ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate('Amount')}
              </span>
              <div className="input-field">
                <Input
                  label={fixedHandleInputLabel}
                  labelPosition={fixedHandleInputLabelPosition}
                  key={'amount'}
                  name={'amount'}
                  placeholder={'Amount'}
                  value={fixedHandleInputValue}
                  onChange={fixedHandleInputChange}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default DynamicForm;
