import user from './initial-states/user';
import dashboard from './initial-states/dashboard';
import addMoney from './initial-states/addMoney';
import payBills from './initial-states/payBills';
import stores from './initial-states/stores';
import countries from './initial-states/countries';
import wallet from './initial-states/wallet';
import voucher from './initial-states/vouchers';
import userAccountManagement from './initial-states/userAccountManagement';
import providersCountries from './initial-states/providersCountries';
import publicity from './initial-states/publicity';
import virtualCards from './initial-states/virtualCard';
import creditCards from './initial-states/creditCard';
import remindUsername from './initial-states/remindUsername';
import microloan from './initial-states/microloan';
import walletsAndBanks from './initial-states/walletsAndBanks';
import gimac from './initial-states/gimac';
import getActivitySectorList from './initial-states/getActivitySectorList';
import organization from './initial-states/organization';
import redeemReferral from './initial-states/referral';
import nSavings from './initial-states/nSavings';

export default {
  user,
  dashboard,
  addMoney,
  payBills,
  stores,
  countries,
  wallet,
  voucher,
  userAccountManagement,
  providersCountries,
  publicity,
  virtualCards,
  creditCards,
  remindUsername,
  microloan,
  walletsAndBanks,
  gimac,
  getActivitySectorList,
  organization,
  redeemReferral,
  nSavings,
};
