import * as ActionType from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case ActionType.CREATE_ORGANIZATION_START:
      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          loading: true,
          error: null,
        },
      };
    case ActionType.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case ActionType.CREATE_ORGANIZATION_ERROR:
      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          data: payload,
          error: null,
          loading: false,
        },
      };

    case ActionType.CLEAR_CREATE_ORGANIZATION_STORE:
      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          error: null,
          loading: false,
          data: null,
        },
      };

    default:
      return null;
  }
};
